import { all, call } from "redux-saga/effects";

import { authSagas } from "./auth.sagas";
import { homeSagas } from "./home.sagas";
import { userDataSagas } from "./userData.sagas";
import { getCollectionsSagas } from "./collections.sagas";
import { getLeaderboardSagas } from "./leaderboard.sagas";
import { friendDetailsSagas } from "./friends.sagas";
import { getCardInfoSagas } from "./card.sagas";
import { rewardSagas } from "./reward.sagas";
import { gallerySagas } from "./gallery.sagas";

export function* sagas() {
  yield all([
    call(authSagas),
    call(homeSagas),
    call(userDataSagas),
    call(getCollectionsSagas),
    call(getLeaderboardSagas),
    call(friendDetailsSagas),
    call(getCardInfoSagas),
    call(rewardSagas),
    call(gallerySagas),
  ]);
}
