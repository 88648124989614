import { call, put, all, takeLatest } from "redux-saga/effects";

import { Api } from "../../api";
import {
  USER_DATA_REQUEST,
  USER_DATA_SUCCESS,
  USER_DATA_FAIL,
  USER_COLLECTIONS_REQUEST,
  USER_COLLECTIONS_SUCCESS,
  USER_COLLECTIONS_FAIL,
  USER_ACHIEVEMENTS_REQUEST,
  USER_ACHIEVEMENTS_SUCCESS,
  USER_ACHIEVEMENTS_FAIL,
  USER_BADGES_REQUEST,
  USER_BADGES_SUCCESS,
  USER_BADGES_FAIL,
  USER_FRIENDS_REQUEST,
  USER_FRIENDS_SUCCESS,
  USER_FRIENDS_FAIL,
  USER_REWARD_COUNTER_REQUEST,
  USER_REWARD_COUNTER_SUCCESS,
  WITH_USER_REWARD_COUNTER
} from "../../constants";

export function* requestUserCollections(action) {
  try {
    const response = yield call(Api.user.getUserCollections, action.payload);

    const { data } = response;

    yield put({
      type: USER_COLLECTIONS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: USER_COLLECTIONS_FAIL,
      payload: {}
    });
  }
}

export function* requestUserAchievements(action) {
  try {
    const response = yield call(Api.user.getUsersAchievements, action.payload);

    const { data } = response;

    yield put({
      type: USER_ACHIEVEMENTS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: USER_ACHIEVEMENTS_FAIL,
      payload: {}
    });
  }
}

export function* requestUserBadges(action) {
  try {
    const response = yield call(Api.user.getUsersBadges, action.payload);

    const { data } = response;

    yield put({
      type: USER_BADGES_SUCCESS,
      payload: data[0],
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: USER_BADGES_FAIL,
      payload: {}
    });
  }
}

export function* requestUserFriends(action) {
  try {
    const response = yield call(Api.user.getUsersFriends, action.payload);

    const { data } = response;

    yield put({
      type: USER_FRIENDS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: USER_FRIENDS_FAIL,
      payload: {}
    });
  }
}

export function* requestUserRewardCounter(action) {
  try {
    const { data } = yield call(Api.reward.getUserRewardCounter, action.payload);

    yield put({
      type: USER_REWARD_COUNTER_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: USER_FRIENDS_FAIL,
      payload: {}
    });
  }
}

export function* requestUserData(action) {
  try {
    const response = yield call(Api.user.getUserData, action.payload);

    const { data } = response;

    if (action.payload.param === "OnlyUserData") {
      yield put({
        type: USER_DATA_SUCCESS,
        payload: data,
      });

      return response;
    }

    if (action.payload.param === WITH_USER_REWARD_COUNTER && data && data.id)
      yield* requestUserRewardCounter({ payload: data.id });

    if (data) {
      yield put({
        type: USER_DATA_SUCCESS,
        payload: data
      });
    }

    return response;
  } catch (err) {
    yield put({
      type: USER_DATA_FAIL
    });
  }
}

export function* requestUserDataSaga() {
  yield takeLatest(USER_DATA_REQUEST, requestUserData);
}

export function* requestUserCollectionsSaga() {
  yield takeLatest(USER_COLLECTIONS_REQUEST, requestUserCollections);
}

export function* requestUserAchievementsSaga() {
  yield takeLatest(USER_ACHIEVEMENTS_REQUEST, requestUserAchievements);
}

export function* requestUserBadgesSaga() {
  yield takeLatest(USER_BADGES_REQUEST, requestUserBadges);
}

export function* requestUserFriendsSaga() {
  yield takeLatest(USER_FRIENDS_REQUEST, requestUserFriends);
}

export function* requestUserRewardCounterSaga() {
  yield takeLatest(USER_REWARD_COUNTER_REQUEST, requestUserRewardCounter);
}

export function* userDataSagas() {
  yield all([
    call(requestUserDataSaga),
    call(requestUserCollectionsSaga),
    call(requestUserAchievementsSaga),
    call(requestUserBadgesSaga),
    call(requestUserFriendsSaga),
    call(requestUserRewardCounterSaga),
  ]);
}