import {
  GET_COLLECTIONS_REQUEST,
  GET_COLLECTIONS_SUCCESS,
  GET_COLLECTIONS_FAIL,
  GET_COLLECTION_BY_ID_REQUEST,
  GET_COLLECTION_BY_ID_SUCCESS,
  GET_COLLECTION_BY_ID_FAIL,
  GET_TOP_AUTHORS_REQUEST,
  GET_TOP_AUTHORS_SUCCESS,
  GET_TOP_AUTHORS_FAIL,
  GET_REWARDED_AUTHORS_REQUEST,
  GET_REWARDED_AUTHORS_SUCCESS,
  GET_REWARDED_AUTHORS_FAIL,
  NOT_AVAILABLE_COLLECTIONS_SLOTS_REQUEST,
  NOT_AVAILABLE_COLLECTIONS_SLOTS_SUCCESS,
  NOT_AVAILABLE_COLLECTIONS_SLOTS_FAIL,
  WHITELISTED_COLLECTIONS_SUCCESS,
  WHITELISTED_COLLECTIONS_FAIL
} from "../../constants";

const initialState = {
  collections: [],
  homePageTopRowCollections: [],
  homePageBottomRowCollections: [],
  collection: {},
  loading: false,
  topAuthors: [],
  rewardedAuthors: [],
  notAvailableCollectionsSlots: null,

  whitelistedCollections: [],
  whitelistedCollectionsFetched: false,
};

export default function collections(state = initialState, action) {
  switch (action.type) {
    case GET_COLLECTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_COLLECTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        collections: action.payload.collectCollections,
        homePageTopRowCollections: action.payload.topRowCollections,
        homePageBottomRowCollections: action.payload.bottomRowCollections
      };

    case GET_COLLECTIONS_FAIL:
      return {
        ...state,
        loading: false,
        collections: [],
        homePageTopRowCollections: [],
        homePageBottomRowCollections: []
      };
    case GET_COLLECTION_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_COLLECTION_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        collection: action.payload,
      };

    case GET_COLLECTION_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        collection: {},
      };

    case GET_TOP_AUTHORS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case GET_TOP_AUTHORS_SUCCESS:
      return {
        ...state,
        loading: false,
        topAuthors: action.payload
      };

    case GET_TOP_AUTHORS_FAIL:
      return {
        ...state,
        loading: false,
        topAuthors: []
      };

    case GET_REWARDED_AUTHORS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case GET_REWARDED_AUTHORS_SUCCESS:
      return {
        ...state,
        loading: false,
        rewardedAuthors: action.payload
      };

    case GET_REWARDED_AUTHORS_FAIL:
      return {
        ...state,
        loading: false,
        rewardedAuthors: []
      };

    case NOT_AVAILABLE_COLLECTIONS_SLOTS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case NOT_AVAILABLE_COLLECTIONS_SLOTS_SUCCESS:
      return {
        ...state,
        loading: false,
        notAvailableCollectionsSlots: action.payload
      };

    case NOT_AVAILABLE_COLLECTIONS_SLOTS_FAIL:
      return {
        ...state,
        loading: false,
        notAvailableCollectionsSlots: null
      };

    case WHITELISTED_COLLECTIONS_SUCCESS:
      return {
        ...state,
        whitelistedCollections: action.payload,
        whitelistedCollectionsFetched: true
      };

    case WHITELISTED_COLLECTIONS_FAIL:
      return {
        ...state,
        whitelistedCollections: [],
        whitelistedCollectionsFetched: true
      };

    default:
      return state;
  }
}
