import { call, put, all, takeEvery } from "redux-saga/effects";

import { Api } from "../../api";
import {
  GET_CARD_INFO_REQUEST,
  GET_CARD_INFO_SUCCESS,
  GET_CARD_INFO_FAIL,
} from "../../constants";

export function* requestCardInfo(action) {
  try {
    const response = yield call(
      Api.collections.getCardInfo,
      action.payload.cardId
    );

    const { data } = response;

    yield put({
      type: GET_CARD_INFO_SUCCESS,
      payload: data[0],
    });
    return response;
  } catch (err) {
    console.log(err);
    yield put({
      type: GET_CARD_INFO_FAIL,
    });
  }
}

function* requestCardInfoSaga() {
  yield takeEvery(GET_CARD_INFO_REQUEST, requestCardInfo);
}

export function* getCardInfoSagas() {
  yield all([call(requestCardInfoSaga)]);
}
