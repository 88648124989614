export const ROWS_SERIES_1 = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'];
export const COLUMNS_SERIES_1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const ROWS_SERIES_2 = ['A', 'B', 'C', 'D', 'E', 'F'];
export const COLUMNS_SERIES_2 = [1, 2, 3, 4, 5, 6];

export const PUZZLE_AREA_A = 'A';
export const PUZZLE_AREA_B = 'B';
export const PUZZLE_AREA_C = 'C';
export const PUZZLE_AREA_D = 'D';
export const PUZZLE_AREA_E = 'E';
export const PUZZLE_AREA_F = 'F';

export const PUZZLE_AREA_A_SERIES_1_PIECE_ID = 1;
export const PUZZLE_AREA_B_SERIES_1_PIECE_ID = 2;
export const PUZZLE_AREA_C_SERIES_1_PIECE_ID = 3;
export const PUZZLE_AREA_D_SERIES_1_PIECE_ID = 4;
export const PUZZLE_AREA_E_SERIES_1_PIECE_ID = 5;
export const PUZZLE_AREA_F_SERIES_1_PIECE_ID = 6;

export const PUZZLE_AREA_A_SERIES_2_PIECE_ID = 7;
export const PUZZLE_AREA_B_SERIES_2_PIECE_ID = 8;
export const PUZZLE_AREA_C_SERIES_2_PIECE_ID = 9;

export const PUZZLE_AREA_A_ELEMENTS = Array.from({ length: 4 }, (_, i) => `A${i + 1}`);
export const PUZZLE_AREA_B_ELEMENTS = Array.from({ length: 12 }, (_, i) => `B${i + 1}`);
export const PUZZLE_AREA_C_ELEMENTS = Array.from({ length: 20 }, (_, i) => `C${i + 1}`);
export const PUZZLE_AREA_D_ELEMENTS = Array.from({ length: 28 }, (_, i) => `D${i + 1}`);
export const PUZZLE_AREA_E_ELEMENTS = Array.from({ length: 36 }, (_, i) => `E${i + 1}`);
export const PUZZLE_AREA_F_ELEMENTS = Array.from({ length: 44 }, (_, i) => `F${i + 1}`);

export const PUZZLE_AREA_A_CLASS = 'pA';
export const PUZZLE_AREA_B_CLASS = 'pB';
export const PUZZLE_AREA_C_CLASS = 'pC';
export const PUZZLE_AREA_D_CLASS = 'pD';
export const PUZZLE_AREA_E_CLASS = 'pE';
export const PUZZLE_AREA_F_CLASS = 'pF';

export const AREA_A_SERIES_1_BONUS_VALUE = "100 Racoon Coins";
export const AREA_A_SERIES_1_BONUS_KEY_CHANGE = 1;
export const AREA_B_SERIES_1_BONUS_VALUE = "300 Racoon Coins";
export const AREA_B_SERIES_1_BONUS_KEY_CHANGE = 3;
export const AREA_C_SERIES_1_BONUS_VALUE = "20 WAX";
export const AREA_C_SERIES_1_BONUS_KEY_CHANGE = 12;
export const AREA_D_SERIES_1_BONUS_VALUE = "80 WAX";
export const AREA_D_SERIES_1_BONUS_KEY_CHANGE = 25;
export const AREA_E_SERIES_1_BONUS_VALUE = "600 WAX";
export const AREA_E_SERIES_1_BONUS_KEY_CHANGE = 100;
export const AREA_F_SERIES_1_BONUS_VALUE = "50000 WAX";
export const AREA_F_SERIES_1_BONUS_KEY_CHANGE = 100;

export const AREA_A_SERIES_2_BONUS_VALUE = "10 WAX";
export const AREA_B_SERIES_2_BONUS_VALUE = "20 WAX";
export const AREA_C_SERIES_2_BONUS_VALUE = "60 WAX";

export const PUZZLE_SERIES_1 = "Series 1";
export const PUZZLE_SERIES_2 = "Series 2";

/* SERIES_1 */
export const A1_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102335' : '407963';
export const A2_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102337' : '407962';
export const A3_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102339' : '407961';
export const A4_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102341' : '407960';

export const B1_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102342' : '407959';
export const B2_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102343' : '407958';
export const B3_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102344' : '407957';
export const B4_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102345' : '407956';
export const B5_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102346' : '407955';
export const B6_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102347' : '407954';
export const B7_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102348' : '407953';
export const B8_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102349' : '407952';
export const B9_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102350' : '407951';
export const B10_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102352' : '407950';
export const B11_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102353' : '407949';
export const B12_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102354' : '407948';

export const C1_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102355' : '407947';
export const C2_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102356' : '407946';
export const C3_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102357' : '407945';
export const C4_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102358' : '407944';
export const C5_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102363' : '407943';
export const C6_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102365' : '407942';
export const C7_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102366' : '407941';
export const C8_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102367' : '407940';
export const C9_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102368' : '407939';
export const C10_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102369' : '407938';
export const C11_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102370' : '407937';
export const C12_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102371' : '407936';
export const C13_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102372' : '407935';
export const C14_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102373' : '407934';
export const C15_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102374' : '407933';
export const C16_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102376' : '407932';
export const C17_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102378' : '407931';
export const C18_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102379' : '407930';
export const C19_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102381' : '407929';
export const C20_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102382' : '407928';

export const D1_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102384' : '407927';
export const D2_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102386' : '407926';
export const D3_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102387' : '407925';
export const D4_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102388' : '407924';
export const D5_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102389' : '407923';
export const D6_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102390' : '407922';
export const D7_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102391' : '407921';
export const D8_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102392' : '407920';
export const D9_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102393' : '407919';
export const D10_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102394' : '407918';
export const D11_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102395' : '407917';
export const D12_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102396' : '407916';
export const D13_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102398' : '407915';
export const D14_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102399' : '407914';
export const D15_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102400' : '407913';
export const D16_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102401' : '407912';
export const D17_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102402' : '407911';
export const D18_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102870' : '407910';
export const D19_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102873' : '407909';
export const D20_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102880' : '407908';
export const D21_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102881' : '407907';
export const D22_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102890' : '407906';
export const D23_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102893' : '407905';
export const D24_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102894' : '407904';
export const D25_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102897' : '407903';
export const D26_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102900' : '407902';
export const D27_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102901' : '407901';
export const D28_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102903' : '407900';

export const E1_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102909' : '407899';
export const E2_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102910' : '407898';
export const E3_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102912' : '407897';
export const E4_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102913' : '407896';
export const E5_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102916' : '407895';
export const E6_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102918' : '407894';
export const E7_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102920' : '407893';
export const E8_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102921' : '407892';
export const E9_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102923' : '407891';
export const E10_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102924' : '407890';
export const E11_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102925' : '407889';
export const E12_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102931' : '407888';
export const E13_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102932' : '407887';
export const E14_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102933' : '407886';
export const E15_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102935' : '407885';
export const E16_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102937' : '407884';
export const E17_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102938' : '407883';
export const E18_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102940' : '407882';
export const E19_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102941' : '407881';
export const E20_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102942' : '407880';
export const E21_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102951' : '407879';
export const E22_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102954' : '407878';
export const E23_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102955' : '407877';
export const E24_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102956' : '407876';
export const E25_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102957' : '407875';
export const E26_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102958' : '407874';
export const E27_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102960' : '407873';
export const E28_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102961' : '407872';
export const E29_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102965' : '407871';
export const E30_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102966' : '407870';
export const E31_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102967' : '407869';
export const E32_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102968' : '407868';
export const E33_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102969' : '407867';
export const E34_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102970' : '407866';
export const E35_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102971' : '407865';
export const E36_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102972' : '407864';

export const F1_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102974' : '407863';
export const F2_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102975' : '407862';
export const F3_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102976' : '407861';
export const F4_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '102994' : '407860';
export const F5_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103031' : '407859';
export const F6_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103039' : '407858';
export const F7_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103041' : '407857';
export const F8_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103045' : '407856';
export const F9_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103046' : '407855';
export const F10_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103048' : '407854';
export const F11_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103053' : '407853';
export const F12_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103077' : '407852';
export const F13_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103140' : '407851';
export const F14_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103144' : '407850';
export const F15_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103149' : '407849';
export const F16_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103150' : '407848';
export const F17_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103151' : '407847';
export const F18_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103152' : '407846';
export const F19_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103155' : '407845';
export const F20_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103157' : '407844';
export const F21_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103160' : '407843';
export const F22_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103162' : '407842';
export const F23_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103163' : '407841';
export const F24_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103164' : '407840';
export const F25_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103167' : '407839';
export const F26_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103168' : '407838';
export const F27_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103169' : '407837';
export const F28_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103170' : '407836';
export const F29_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103171' : '407835';
export const F30_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103173' : '407834';
export const F31_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103176' : '407833';
export const F32_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103179' : '407832';
export const F33_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103182' : '407831';
export const F34_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103184' : '407830';
export const F35_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103186' : '407829';
export const F36_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103190' : '407828';
export const F37_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103192' : '407827';
export const F38_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103195' : '407826';
export const F39_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103196' : '407825';
export const F40_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103199' : '407824';
export const F41_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103201' : '407823';
export const F42_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103204' : '407822';
export const F43_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103206' : '407821';
export const F44_SERIES_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '103208' : '407820';

/* SERIES_2 */
export const A1_SERIES_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '514442' : '398171';
export const A2_SERIES_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '514445' : '398177';
export const A3_SERIES_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '514446' : '398178';
export const A4_SERIES_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '514447' : '398179';

export const B1_SERIES_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '514452' : '409707';
export const B2_SERIES_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '514456' : '409708';
export const B3_SERIES_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '514458' : '409709';
export const B4_SERIES_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '514459' : '409710';
export const B5_SERIES_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '514461' : '409711';
export const B6_SERIES_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '514462' : '409712';
export const B7_SERIES_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '514463' : '409713';
export const B8_SERIES_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '514464' : '409714';
export const B9_SERIES_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '514465' : '409715';
export const B10_SERIES_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '514466' : '409716';
export const B11_SERIES_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '514467' : '409717';
export const B12_SERIES_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '514468' : '409718';

export const C1_SERIES_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '514957' : '409719';
export const C2_SERIES_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '514958' : '409720';
export const C3_SERIES_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '514959' : '409721';
export const C4_SERIES_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '514960' : '409722';
export const C5_SERIES_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '514961' : '409723';
export const C6_SERIES_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '514962' : '409724';
export const C7_SERIES_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '514964' : '409725';
export const C8_SERIES_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '514965' : '409726';
export const C9_SERIES_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '514966' : '409727';
export const C10_SERIES_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '514967' : '409728';
export const C11_SERIES_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '514968' : '409729';
export const C12_SERIES_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '514969' : '409730';
export const C13_SERIES_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '514970' : '409731';
export const C14_SERIES_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '514971' : '409732';
export const C15_SERIES_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '514972' : '409733';
export const C16_SERIES_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '514973' : '409734';
export const C17_SERIES_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '514974' : '409735';
export const C18_SERIES_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '514975' : '409736';
export const C19_SERIES_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '514976' : '409737';
export const C20_SERIES_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '514977' : '409738';

export const SERIES_2_TEMPLATE_IDS = [A1_SERIES_2_TEMPLATE_ID, A2_SERIES_2_TEMPLATE_ID, A3_SERIES_2_TEMPLATE_ID, A4_SERIES_2_TEMPLATE_ID, B1_SERIES_2_TEMPLATE_ID, B2_SERIES_2_TEMPLATE_ID, B3_SERIES_2_TEMPLATE_ID, B4_SERIES_2_TEMPLATE_ID, B5_SERIES_2_TEMPLATE_ID, B6_SERIES_2_TEMPLATE_ID, B7_SERIES_2_TEMPLATE_ID, B8_SERIES_2_TEMPLATE_ID, B9_SERIES_2_TEMPLATE_ID, B10_SERIES_2_TEMPLATE_ID, B11_SERIES_2_TEMPLATE_ID, B12_SERIES_2_TEMPLATE_ID, C1_SERIES_2_TEMPLATE_ID, C2_SERIES_2_TEMPLATE_ID, C3_SERIES_2_TEMPLATE_ID, C4_SERIES_2_TEMPLATE_ID, C5_SERIES_2_TEMPLATE_ID, C6_SERIES_2_TEMPLATE_ID, C7_SERIES_2_TEMPLATE_ID, C8_SERIES_2_TEMPLATE_ID, C9_SERIES_2_TEMPLATE_ID, C10_SERIES_2_TEMPLATE_ID, C11_SERIES_2_TEMPLATE_ID, C12_SERIES_2_TEMPLATE_ID, C13_SERIES_2_TEMPLATE_ID, C14_SERIES_2_TEMPLATE_ID, C15_SERIES_2_TEMPLATE_ID, C16_SERIES_2_TEMPLATE_ID, C17_SERIES_2_TEMPLATE_ID, C18_SERIES_2_TEMPLATE_ID, C19_SERIES_2_TEMPLATE_ID, C20_SERIES_2_TEMPLATE_ID];

