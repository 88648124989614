import {
  USER_ASSETS_SUCCESS,
  AUTH_LOGOUT_SUCCESS,
  MAX_VIEWERS_SUCCESS,

  SLOTS_CONFIG_SUCCESS,
  SLOTS_CONFIG_FAIL,

  BORDER_CONFIG_SUCCESS,
  BORDER_CONFIG_FAIL,

  LOCKED_TOKENS_SUCCESS,
  LOCKED_TOKENS_FAIL,

  BOOSTERS_CONFIG_SUCCESS,
  BOOSTERS_CONFIG_FAIL,

  GALLERY_BALANCE_SUCCESS,
  GALLERY_BALANCE_FAIL,

  GALLERY_DATA_REQUEST,
  GALLERY_DATA_SUCCESS,
  GALLERY_DATA_FAIL,

  MINING_INFO_FAIL,
  MINING_INFO_SUCCESS,

  USER_BOOSTERS_SUCCESS,
  USER_BOOSTERS_FAIL,
  ADD_USER_BOOSTER,
  REMOVE_USER_BOOSTER
} from "../../constants";

const initialState = {
  galleryData: [],
  galleryDataLoading: false,
  galleryDataFetched: false,

  miningInfo: {},
  miningInfoFetched: false,

  borderConfig: [],
  borderConfigFetched: false,

  slotsConfig: [],
  slotsConfigFetched: false,
  
  boostersConfig: [],
  boostersConfigFetched: false,

  lockedTokens: [],
  lockedTokensFetched: false,

  maxViewers: 0,
  maxViewersFetched: false,

  userAssets: [],
  userAssetsFetched: false,

  galleryBalance: {
    racoonBalance: 0,
    waxBalance: 0,
  },
  galleryBalanceFetched: false,

  userBoosters: [],
  userBoostersFetched: false,
};

export default function gallery(state = initialState, action) {
  switch (action.type) {
    case GALLERY_DATA_REQUEST:
      return {
        ...state,
        galleryDataLoading: true
      };

    case GALLERY_DATA_SUCCESS:
      return {
        ...state,
        galleryData: action.payload,
        galleryDataLoading: false,
        galleryDataFetched: true
      };

    case GALLERY_DATA_FAIL:
      return {
        ...state,
        galleryData: [],
        galleryDataLoading: false,
        galleryDataFetched: true
      };  

    case MINING_INFO_SUCCESS:
      return {
        ...state,
        miningInfo: action.payload,
        miningInfoFetched: true,
      };

    case MINING_INFO_FAIL:
      return {
        ...state,
        miningInfo: {},
        miningInfoFetched: true,
      };  

    case BORDER_CONFIG_SUCCESS:
      return {
        ...state,
        borderConfig: action.payload,
        borderConfigFetched: true,
      };

    case BORDER_CONFIG_FAIL:
      return {
        ...state,
        borderConfig: [],
        borderConfigFetched: true,
      };

    case SLOTS_CONFIG_SUCCESS:
      return {
        ...state,
        slotsConfig: action.payload,
        slotsConfigFetched: true,
      };

    case SLOTS_CONFIG_FAIL:
      return {
        ...state,
        slotsConfig: [],
        slotsConfigFetched: true,
      };

    case BOOSTERS_CONFIG_SUCCESS:
      return {
        ...state,
        boostersConfig: action.payload,
        boostersConfigFetched: true,
      };

    case BOOSTERS_CONFIG_FAIL:
      return {
        ...state,
        boostersConfig: [],
        boostersConfigFetched: true,
      };

    case LOCKED_TOKENS_SUCCESS:
      return {
        ...state,
        lockedTokens: action.payload,
        lockedTokensFetched: true,
      };

    case LOCKED_TOKENS_FAIL:
      return {
        ...state,
        lockedTokens: [],
        lockedTokensFetched: true,
      };  

    case MAX_VIEWERS_SUCCESS:
      return {
        ...state,
        maxViewers: action.payload,
        maxViewersFetched: true,
      };

    case USER_ASSETS_SUCCESS:
      return {
        ...state,
        userAssets: action.payload,
        userAssetsFetched: true
      };

    case GALLERY_BALANCE_SUCCESS:
      return {
        ...state,
        galleryBalance: {
          racoonBalance: action.payload.racoonBalance,
          waxBalance: action.payload.waxBalance,
        },
        galleryBalanceFetched: true,
      };

    case GALLERY_BALANCE_FAIL:
      return {
        ...state,
        galleryBalance: {
          racoonBalance: 0,
          waxBalance: 0,
        },
        galleryBalanceFetched: true,
      };

    case USER_BOOSTERS_SUCCESS:
      return {
        ...state,
        userBoosters: action.payload,
        userBoostersFetched: true
      };

    case USER_BOOSTERS_FAIL:
      return {
        ...state,
        userBoosters: [],
        userBoostersFetched: true
      };

    case ADD_USER_BOOSTER:
      return {
        ...state,
        userBoosters: [...state.userBoosters, action.payload]
      };

    case REMOVE_USER_BOOSTER:
      return {
        ...state,
        userBoosters: state.userBoosters.filter(({ asset_id }) => asset_id !== action.payload)
      };

    case AUTH_LOGOUT_SUCCESS:
      return {
        ...state,
        lockedTokens: [],
        lockedTokensFetched: false,
        userAssets: [],
        userAssetsFetched: false,
        galleryBalance: {
          racoonBalance: 0,
          waxBalance: 0,
        },
        galleryBalanceFetched: false,
        userBoosters: [],
        userBoostersFetched: false,
      };

    default:
      return state;
  }
}
