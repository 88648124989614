/*
|  App
*/

export const APP_START = "APP_START";

export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";

export const LANGUAGE_EN = "en-GB";
export const LANGUAGE_TR = "tr-TR";

export const LANGUAGE_DEFAULT = LANGUAGE_EN;
export const MISSING_TRANSLATION_MESSAGE = "Missing Translation";

export const WITH_USER_REWARD_COUNTER = "WithUserRewardCounter";

/*
|  Gallery
*/

export const GALLERY_DATA_REQUEST = "GALLERY_DATA_REQUEST";
export const GALLERY_DATA_SUCCESS = "GALLERY_DATA_SUCCESS";
export const GALLERY_DATA_FAIL = "GALLERY_DATA_FAIL";

export const MINING_INFO_REQUEST = "MINING_INFO_REQUEST";
export const MINING_INFO_SUCCESS = "MINING_INFO_SUCCESS";
export const MINING_INFO_FAIL = "MINING_INFO_FAIL";

export const BORDER_CONFIG_REQUEST = "BORDER_CONFIG_REQUEST";
export const BORDER_CONFIG_SUCCESS = "BORDER_CONFIG_SUCCESS";
export const BORDER_CONFIG_FAIL = "BORDER_CONFIG_FAIL";

export const SLOTS_CONFIG_REQUEST = "SLOTS_CONFIG_REQUEST";
export const SLOTS_CONFIG_SUCCESS = "SLOTS_CONFIG_SUCCESS";
export const SLOTS_CONFIG_FAIL = "SLOTS_CONFIG_FAIL";

export const BOOSTERS_CONFIG_REQUEST = "BOOSTERS_CONFIG_REQUEST";
export const BOOSTERS_CONFIG_SUCCESS = "BOOSTERS_CONFIG_SUCCESS";
export const BOOSTERS_CONFIG_FAIL = "BOOSTERS_CONFIG_FAIL";

export const LOCKED_TOKENS_REQUEST = "LOCKED_TOKENS_REQUEST";
export const LOCKED_TOKENS_SUCCESS = "LOCKED_TOKENS_SUCCESS";
export const LOCKED_TOKENS_FAIL = "LOCKED_TOKENS_FAIL";

export const MAX_VIEWERS_REQUEST = "MAX_VIEWERS_REQUEST";
export const MAX_VIEWERS_SUCCESS = "MAX_VIEWERS_SUCCESS";
export const MAX_VIEWERS_FAIL = "MAX_VIEWERS_FAIL";

export const USER_ASSETS_SUCCESS = "USER_ASSETS_SUCCESS";

export const GALLERY_BALANCE_REQUEST = "GALLERY_BALANCE_REQUEST";
export const GALLERY_BALANCE_SUCCESS = "GALLERY_BALANCE_SUCCESS";
export const GALLERY_BALANCE_FAIL = "GALLERY_BALANCE_FAIL";

export const USER_BOOSTERS_REQUEST = "USER_BOOSTERS_REQUEST";
export const USER_BOOSTERS_SUCCESS = "USER_BOOSTERS_SUCCESS";
export const USER_BOOSTERS_FAIL = "USER_BOOSTERS_FAIL";
export const ADD_USER_BOOSTER = "ADD_USER_BOOSTER";
export const REMOVE_USER_BOOSTER = "REMOVE_USER_BOOSTER";

/*
|  Referral
*/
export const REFERRAL_COOKIES_KEY = "REFERRAL";

/*
|  Auth
*/

export const AUTH_LOGIN_REQUEST = "AUTH_LOGIN_REQUEST";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_FAIL = "AUTH_LOGIN_FAIL";

export const AUTH_LOGOUT_REQUEST = "AUTH_LOGOUT_REQUEST";
export const AUTH_LOGOUT_SUCCESS = "AUTH_LOGOUT_SUCCESS";
export const AUTH_LOGOUT_FAIL = "AUTH_LOGOUT_FAIL";

export const AUTH_REGISTER_REQUEST = "AUTH_REGISTER_REQUEST";
export const AUTH_REGISTER_SUCCESS = "AUTH_REGISTER_SUCCESS";
export const AUTH_REGISTER_FAIL = "AUTH_REGISTER_FAIL";

export const AUTH_PROFILE_REQUEST = "AUTH_PROFILE_REQUEST";
export const AUTH_PROFILE_FAIL = "AUTH_PROFILE_FAIL";

export const HOME_LOAD_REQUEST = "HOME_LOAD_REQUEST";
export const HOME_LOAD_SUCCESS = "HOME_LOAD_SUCCESS";
export const HOME_LOAD_FAIL = "HOME_LOAD_FAIL";

export const USER_DATA_REQUEST = "USER_DATA_REQUEST";
export const USER_DATA_SUCCESS = "USER_DATA_SUCCESS";
export const USER_DATA_FAIL = "USER_DATA_FAIL";

export const GET_COLLECTIONS_REQUEST = "GET_COLLECTIONS_REQUEST";
export const GET_COLLECTIONS_SUCCESS = "GET_COLLECTIONS_SUCCESS";
export const GET_COLLECTIONS_FAIL = "GET_COLLECTIONS_FAIL";

export const USER_COLLECTIONS_REQUEST = "USER_COLLECTIONS_REQUEST";
export const USER_COLLECTIONS_SUCCESS = "USER_COLLECTIONS_SUCCESS";
export const USER_COLLECTIONS_FAIL = "USER_COLLECTIONS_FAIL";

export const USER_ACHIEVEMENTS_REQUEST = "USER_ACHIEVEMENTS_REQUEST";
export const USER_ACHIEVEMENTS_SUCCESS = "USER_ACHIEVEMENTS_SUCCESS";
export const USER_ACHIEVEMENTS_FAIL = "USER_ACHIEVEMENTS_FAIL";

export const USER_BADGES_REQUEST = "USER_BADGES_REQUEST";
export const USER_BADGES_SUCCESS = "USER_BADGES_SUCCESS";
export const USER_BADGES_FAIL = "USER_BADGES_FAIL";

export const USER_FRIENDS_REQUEST = "USER_FRIENDS_REQUEST";
export const USER_FRIENDS_SUCCESS = "USER_FRIENDS_SUCCESS";
export const USER_FRIENDS_FAIL = "USER_FRIENDS_FAIL";

export const GET_FRIEND_DETAILS_REQUEST = "GET_FRIEND_DETAILS_REQUEST";
export const GET_FRIEND_DETAILS_SUCCESS = "GET_FRIEND_DETAILS_SUCCESS";
export const GET_FRIEND_DETAILS_FAIL = "GET_FRIEND_DETAILS_FAIL";

export const FRIEND_DATA_REQUEST = "FRIEND_DATA_REQUEST";
export const FRIEND_DATA_SUCCESS = "FRIEND_DATA_SUCCESS";
export const FRIEND_DATA_FAIL = "FRIEND_DATA_FAIL";

export const GET_LEADERBOARD_DATA_REQUEST = "GET_LEADERBOARD_DATA_REQUEST";
export const GET_LEADERBOARD_DATA_SUCCESS = "GET_LEADERBOARD_DATA_SUCCESS";
export const GET_LEADERBOARD_DATA_FAIL = "GET_LEADERBOARD_DATA_FAIL";

export const GET_COLLECTION_BY_ID_REQUEST = "GET_COLLECTION_BY_ID_REQUEST";
export const GET_COLLECTION_BY_ID_SUCCESS = "GET_COLLECTION_BY_ID_SUCCESS";
export const GET_COLLECTION_BY_ID_FAIL = "GET_COLLECTION_BY_ID_FAIL";

export const GET_CARD_INFO_REQUEST = "GET_CARD_INFO_REQUEST";
export const GET_CARD_INFO_SUCCESS = "GET_CARD_INFO_SUCCESS";
export const GET_CARD_INFO_FAIL = "GET_CARD_INFO_FAIL";

export const GET_NEWS_REQUEST = "GET_NEWS_REQUEST";
export const GET_NEWS_SUCCESS = "GET_NEWS_SUCCESS";
export const GET_NEWS_FAIL = "GET_NEWS_FAIL";

export const GET_TOP_AUTHORS_REQUEST = "GET_TOP_AUTHORS_REQUEST";
export const GET_TOP_AUTHORS_SUCCESS = "GET_TOP_AUTHORS_SUCCESS";
export const GET_TOP_AUTHORS_FAIL = "GET_TOP_AUTHORS_FAIL";

export const GET_REWARDED_AUTHORS_REQUEST = "GET_REWARDED_AUTHORS_REQUEST";
export const GET_REWARDED_AUTHORS_SUCCESS = "GET_REWARDED_AUTHORS_SUCCESS";
export const GET_REWARDED_AUTHORS_FAIL = "GET_REWARDED_AUTHORS_FAIL";

export const USER_REWARD_COUNTER_REQUEST = "USER_REWARD_COUNTER_REQUEST";
export const USER_REWARD_COUNTER_SUCCESS = "USER_REWARD_COUNTER_SUCCESS";
export const USER_REWARD_COUNTER_FAIL = "USER_REWARD_COUNTER_FAIL";

export const NOT_AVAILABLE_COLLECTIONS_SLOTS_REQUEST = "NOT_AVAILABLE_COLLECTIONS_SLOTS_REQUEST";
export const NOT_AVAILABLE_COLLECTIONS_SLOTS_SUCCESS = "NOT_AVAILABLE_COLLECTIONS_SLOTS_SUCCESS";
export const NOT_AVAILABLE_COLLECTIONS_SLOTS_FAIL = "NOT_AVAILABLE_COLLECTIONS_SLOTS_FAIL";

export const WHITELISTED_COLLECTIONS_REQUEST = "WHITELISTED_COLLECTIONS_REQUEST";
export const WHITELISTED_COLLECTIONS_SUCCESS = "WHITELISTED_COLLECTIONS_SUCCESS";
export const WHITELISTED_COLLECTIONS_FAIL = "WHITELISTED_COLLECTIONS_FAIL";

export const POTENTIAL_REWARDS_REQUEST = "POTENTIAL_REWARDS_REQUEST";
export const POTENTIAL_REWARDS_SUCCESS = "POTENTIAL_REWARDS_SUCCESS";
export const POTENTIAL_REWARDS_FAIL = "POTENTIAL_REWARDS_FAIL";

/*
|  Helper functions
*/
// This function is for centering swipeable content for tablet/mobile.
export const centerContent = (currentRef) => {
  currentRef.scrollLeft = (currentRef.scrollWidth - currentRef.offsetWidth) / 2;
};

/*
|  Chain
*/

export const WAX_CHAIN_ID = process.env.WAX_CHAIN === 'mainnet'
    ? '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4'
    : 'f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12';

export const WAX_RPC_ENDPOINTS_HOST = process.env.WAX_CHAIN === 'mainnet'
    ? "wax.cryptolions.io"
    : "testnet.waxsweden.org";

export const WAX_RPC_ENDPOINTS_PROTOCOL = "https";
