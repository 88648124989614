import { call, put, all, takeLatest } from "redux-saga/effects";

import { Api } from "../../api";
import {
  POTENTIAL_REWARDS_REQUEST,
  POTENTIAL_REWARDS_SUCCESS,
  POTENTIAL_REWARDS_FAIL
} from "../../constants";

export function* requestUserPotentialRewards(action) {
  try {
    const { data } = yield call(Api.reward.getPotentialRewards, action.payload);

    yield put({
      type: POTENTIAL_REWARDS_SUCCESS,
      payload: data
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: POTENTIAL_REWARDS_FAIL,
      payload: []
    });
  }
}

export function* requestUserPotentialRewardsSaga() {
  yield takeLatest(POTENTIAL_REWARDS_REQUEST, requestUserPotentialRewards);
}

export function* rewardSagas() {
  yield all([
    call(requestUserPotentialRewardsSaga)
  ]);
}
