import {
  POTENTIAL_REWARDS_REQUEST,
  POTENTIAL_REWARDS_SUCCESS,
  POTENTIAL_REWARDS_FAIL,
  AUTH_LOGOUT_SUCCESS,
} from "../../constants";

const initialState = {
  potentialRewards: null,
  loading: false
};

export default function reward(state = initialState, action) {
  switch (action.type) {
    case POTENTIAL_REWARDS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case POTENTIAL_REWARDS_SUCCESS:
      return {
        ...state,
        loading: false,
        potentialRewards: action.payload
      };

    case POTENTIAL_REWARDS_FAIL:
      return {
        ...state,
        loading: false,
        potentialRewards: []
      };

    case AUTH_LOGOUT_SUCCESS:
      return {
        ...state,
        potentialRewards: [],
      };

    default:
      return state;
  }
};
