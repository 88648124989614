import { call, put, all, takeLatest } from "redux-saga/effects";

import { toast } from "react-toastify";

import {
  GALLERY_DATA_REQUEST,
  GALLERY_DATA_SUCCESS,
  GALLERY_DATA_FAIL,

  MINING_INFO_REQUEST,
  MINING_INFO_SUCCESS,
  MINING_INFO_FAIL,

  SLOTS_CONFIG_REQUEST,
  SLOTS_CONFIG_SUCCESS,
  SLOTS_CONFIG_FAIL,

  BORDER_CONFIG_REQUEST,
  BORDER_CONFIG_SUCCESS,
  BORDER_CONFIG_FAIL,

  BOOSTERS_CONFIG_REQUEST,
  BOOSTERS_CONFIG_SUCCESS,
  BOOSTERS_CONFIG_FAIL,

  LOCKED_TOKENS_REQUEST,
  LOCKED_TOKENS_SUCCESS,
  LOCKED_TOKENS_FAIL,

  MAX_VIEWERS_REQUEST,
  MAX_VIEWERS_SUCCESS,
  MAX_VIEWERS_FAIL, 
  
  GALLERY_BALANCE_REQUEST,
  GALLERY_BALANCE_SUCCESS,
  GALLERY_BALANCE_FAIL,
    
  USER_BOOSTERS_REQUEST,
  USER_BOOSTERS_SUCCESS,
  USER_BOOSTERS_FAIL
} from "../../constants";

import {
  fetchBoosters,
  fetchBoostersConfig,
  fetchBorderConfig,
  fetchGalleryBalance,
  fetchGalleryData,
  fetchGalleryMiningData,
  fetchLockedTokens,
  fetchMaxViewers,
  fetchSlotsConfig
} from "../../services/gallery.service";

export function* requestGalleryData() {
  try {
    const galleryData = yield call(fetchGalleryData);

    yield put({
      type: GALLERY_DATA_SUCCESS,
      payload: galleryData
    });
  } catch (e) {
    toast.error(e.message);
    
    yield put({ type: GALLERY_DATA_FAIL });
  }
}

export function* requestGalleryMiningData() {
  try {
    const miningData = yield call(fetchGalleryMiningData);

    yield put({
      type: MINING_INFO_SUCCESS,
      payload: miningData
    });
  } catch (e) {
    toast.error(e.message);

    yield put({ type: MINING_INFO_FAIL });
  }
}

export function* requestSlotsConfig() {
  try {
    const slotsConfig = yield call(fetchSlotsConfig);

    yield put({
      type: SLOTS_CONFIG_SUCCESS,
      payload: slotsConfig
    });
  } catch (e) {
    toast.error(e.message);

    yield put({ type: SLOTS_CONFIG_FAIL });
  }
}

export function* requestBorderConfig() {
  try {
    const borderConfig = yield call(fetchBorderConfig);

    yield put({
      type: BORDER_CONFIG_SUCCESS,
      payload: borderConfig
    });
  } catch (e) {
    toast.error(e.message);

    yield put({ type: BORDER_CONFIG_FAIL });
  }
}

export function* requestBoostersConfig() {
  try {
    const boosterConfig = yield call(fetchBoostersConfig);

    yield put({
      type: BOOSTERS_CONFIG_SUCCESS,
      payload: boosterConfig
    });
  } catch (e) {
    toast.error(e.message);

    yield put({ type: BOOSTERS_CONFIG_FAIL });
  }
}

export function* requestLockedTokens(action) {
  try {
    const account = action.payload;
    
    const lockedTokens = yield call(fetchLockedTokens, account);

    yield put({
      type: LOCKED_TOKENS_SUCCESS,
      payload: lockedTokens
    });
  } catch (e) {
    toast.error(e.message);

    yield put({ type: LOCKED_TOKENS_FAIL });
  }
}

export function* requestMaxViewers() {
  try {
    const maxViewers = yield call(fetchMaxViewers);

    yield put({
      type: MAX_VIEWERS_SUCCESS,
      payload: maxViewers
    });
  } catch (e) {
    toast.error(e.message);

    yield put({ type: MAX_VIEWERS_FAIL });
  }
}

export function* requestGalleryBalance(action) {
  try {
    const account = action.payload;

    const galleryBalance = yield call(fetchGalleryBalance, account);

    yield put({
      type: GALLERY_BALANCE_SUCCESS,
      payload: {
        racoonBalance: galleryBalance ? galleryBalance.balance_racoon : 0,
        waxBalance: galleryBalance ? galleryBalance.balance_wax : 0
      }
    });
  } catch (e) {
    toast.error(e.message);

    yield put({ type: GALLERY_BALANCE_FAIL });
  }
}

export function* requestUserBoosters(action) {
  try {
    const account = action.payload;

    const userBoosters = yield call(fetchBoosters, { owner: account });

    yield put({
      type: USER_BOOSTERS_SUCCESS,
      payload: userBoosters
    });
  } catch (e) {
    toast.error(e.message);

    yield put({ type: USER_BOOSTERS_FAIL });
  }
}

export function* requestGalleryDataSaga() {
  yield takeLatest(GALLERY_DATA_REQUEST, requestGalleryData);
}

export function* requestGalleryMiningDataSaga() {
  yield takeLatest(MINING_INFO_REQUEST, requestGalleryMiningData);
}

export function* requestSlotsConfigSaga() {
  yield takeLatest(SLOTS_CONFIG_REQUEST, requestSlotsConfig);
}

export function* requestBorderConfigSaga() {
  yield takeLatest(BORDER_CONFIG_REQUEST, requestBorderConfig);
}

export function* requestBoostersConfigSaga() {
  yield takeLatest(BOOSTERS_CONFIG_REQUEST, requestBoostersConfig);
}

export function* requestLockedTokensSaga() {
  yield takeLatest(LOCKED_TOKENS_REQUEST, requestLockedTokens);
}

export function* requestMaxViewersSaga() {
  yield takeLatest(MAX_VIEWERS_REQUEST, requestMaxViewers);
}

export function* requestGalleryBalanceSaga() {
  yield takeLatest(GALLERY_BALANCE_REQUEST, requestGalleryBalance);
}

export function* requestUserBoostersSaga() {
  yield takeLatest(USER_BOOSTERS_REQUEST, requestUserBoosters);
}

export function* gallerySagas() {
  yield all([
    call(requestGalleryDataSaga),
    call(requestGalleryMiningDataSaga),
    call(requestSlotsConfigSaga),
    call(requestBorderConfigSaga),
    call(requestBoostersConfigSaga),
    call(requestLockedTokensSaga),
    call(requestMaxViewersSaga),
    call(requestGalleryBalanceSaga),
    call(requestUserBoostersSaga),
  ]);
}
