export const COLLECT_WHALE = 'collectwhale';
export const DARKCOUNTRY_N = 'darkcountryn';

export const APRIL_PACK_SCHEMA = 'aprilpack';
export const PUZZLE_SCHEMA = 'puzzle';
export const PUZZLE_BONUS_SCHEMA = 'bonus';
export const KEY_SCHEMA = 'key';

export const UNBOXED_ASSETS = 'unboxassets';
export const UNBOXED_PACKS = 'unboxpacks';

export const NFT_GALLERY_ACCOUNT = 'collectalley';
export const GALLERY_SCHEMA = 'gallery';
export const BOOSTER_SCHEMA = 'booster';

export const ATOMIC_ASSETS = 'atomicassets';
export const ATOMIC_PACKS = 'atomicpacksx';
