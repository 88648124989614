const ENDPOINT_COLLECT_BACKEND = "https://immortalgames.io/api";
const ENDPOINT_LOCAL_COLLECT_BACKEND = "http://localhost:8000";

export const to = (path) => {
  return `${ENDPOINT_COLLECT_BACKEND}/${path}`;
};

export const toLocal = (path) => {
  return `${ENDPOINT_LOCAL_COLLECT_BACKEND}/${path}`;
};
