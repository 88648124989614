import axios from "axios";
import { to } from "../../helpers/api";

export const reward = {
  getUserRewardCounter: (id) => {
    return axios.get(to(`reward/counter/${id}`));
  },

  getRewardedAuthors: () => {
    return axios.get(to(`reward/author/rewarded`));
  },

  getPotentialRewards: (account) => {
    return axios.get(to(`reward/potential-rewards/${account}`));
  },
};
