import axios from "axios";

import { to } from "../../helpers/api";

export const collections = {
  getCollections: () => {
    return axios.get(to('collect'));
  },

  getCollectionById: (id) => {
    return axios.get(to(`collect/${id}`));
  },

  getTopAuthors: () => {
    return axios.get(to(`collect/top/authors`));
  },

  claim: ({ id, collectionsToClaim, assetIdsToClaim }) => {
    return axios.post(to(`collect/claim/${id}`), { collectionsToClaim, assetIdsToClaim });
  }
};
