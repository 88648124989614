import {
  SWAP_TILES,
  RESET_SWAP,
  SELECT_TILE,
  UNSELECT_TILE,
  UPDATE_TILE_STATUS,
  UPDATE_GAME_STATUS,
  SET_IMAGE_URL,
  BOARD_SIZE,
  RESET_GAME,
} from "../../constants/puzzleGame.constants";
import { getRandomPuzzleGameImage } from "../../helpers/images";

const initialState = {
  swap: false,
  tiles: [],
  tilesStatus: {},
  minSwapsMode: { threshold: 10 },
  isPlaying: true,
  hasWon: false,

  boardRows: 3,
  boardColumns: 3,

  width: BOARD_SIZE,
  height: BOARD_SIZE,
  url: getRandomPuzzleGameImage(),

  resetGame: false,
};

export default function puzzleGame(state = initialState, action) {
  switch (action.type) {
    case SWAP_TILES:
      return { ...state, swap: true };

    case RESET_SWAP:
      return { ...state, swap: false, tiles: [] };

    case SELECT_TILE:
      return { ...state, tiles: [...state.tiles, action.payload] };

    case UNSELECT_TILE:
      const coords = action.payload;

      return {
        ...state,
        tiles: state.tiles.filter(tile => !tile.every((coord, index) => coord === coords[index]))
      };

    case UPDATE_TILE_STATUS:
      return {
        ...state,
        tilesStatus: {
          ...state.tilesStatus,
          [action.payload.id]: action.payload.status
        }
      };

    case UPDATE_GAME_STATUS:
      return { ...state, isPlaying: action.payload.isPlaying, hasWon: action.payload.hasWon };

    case SET_IMAGE_URL:
      return { ...state, url: action.payload };

    case RESET_GAME:
      return {
        ...state,
        hasWon: false,
        isPlaying: true,
        resetGame: !state.resetGame
      };

    default:
      return { ...state };
  }
}
