import {
  HOME_LOAD_REQUEST,
  HOME_LOAD_SUCCESS,
  HOME_LOAD_FAIL,
} from "../../constants";

const initialState = {
  items: [],
  loading: false,
};

export default function home(state = initialState, action) {
  switch (action.type) {
    case HOME_LOAD_REQUEST:
      return {
        ...state,
        items: [],
        loading: true,
      };

    case HOME_LOAD_SUCCESS:
      return {
        ...state,
        items: action.payload,
        loading: false,
      };

    case HOME_LOAD_FAIL:
      return {
        ...state,
        items: [],
        loading: false,
      };

    default:
      return state;
  }
}
