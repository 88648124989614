import {
  GET_LEADERBOARD_DATA_REQUEST,
  GET_LEADERBOARD_DATA_SUCCESS,
  GET_LEADERBOARD_DATA_FAIL,
} from "../../constants";

const initialState = {
  leaderboard: [],
  loading: false,
};

export default function leaderboard(state = initialState, action) {
  switch (action.type) {
    case GET_LEADERBOARD_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        leaderboard: [],
      };
    case GET_LEADERBOARD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        leaderboard: [...state.leaderboard, ...action.payload],
      };

    case GET_LEADERBOARD_DATA_FAIL:
      return {
        ...state,
        loading: false,
        leaderboard: [],
      };

    default:
      return state;
  }
}
