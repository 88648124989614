import { call, put, all, takeLatest } from "redux-saga/effects";

import { Api } from "../../api";
import {
  GET_LEADERBOARD_DATA_REQUEST,
  GET_LEADERBOARD_DATA_SUCCESS,
  GET_LEADERBOARD_DATA_FAIL,
} from "../../constants";

export function* requestGetLeaderboard() {
  try {
    const leaderboardByCollections = yield call(
      Api.leaderboard.getLeaderboard,
      'collections'
    );
    const leaderboardByExperience = yield call(
      Api.leaderboard.getLeaderboard,
      `experience`
    );
    const leaderboardByValue = yield call(
      Api.leaderboard.getLeaderboard,
      `value`
    );

    if (leaderboardByCollections && leaderboardByExperience && leaderboardByValue) {
      const payload = {
        leaderboard: [
          { name: 'Collections', data: leaderboardByCollections.data },
          { name: 'Experience', data: leaderboardByExperience.data },
          { name: 'Value', data: leaderboardByValue.data },
        ],
      };

      yield put({
        type: GET_LEADERBOARD_DATA_SUCCESS,
        payload: payload.leaderboard,
      });

      return payload.leaderboard;
    }
  } catch (err) {
    console.log(err);
    yield put({
      type: GET_LEADERBOARD_DATA_FAIL,
    });
  }
}

function* requestGetLeaderboardSaga() {
  yield takeLatest(GET_LEADERBOARD_DATA_REQUEST, requestGetLeaderboard);
}

export function* getLeaderboardSagas() {
  yield all([call(requestGetLeaderboardSaga)]);
}
