import axios from "axios";
import { store } from "../index";

import { LOCAL_STORAGE_ACCESS_TOKEN, LOCAL_STORAGE_REFRESH_TOKEN } from "../constants/localStorage.constants";
import { getFromLocalStorageWithExpiry } from "../helpers/storage";
import { setAuthTokensToStorage } from "../helpers/auth";
import { to } from "../helpers/api";

//request interceptor to add the auth token header to requests
axios.interceptors.request.use(
  (config) => {
    const accessToken = getFromLocalStorageWithExpiry({ key: LOCAL_STORAGE_ACCESS_TOKEN });

    if (accessToken) {
      const { auth: { auth: { id } } } = store.getState();

      config.headers['Authorization'] = accessToken;
      config.headers['Authorization-User-Id'] = id;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//response interceptor to refresh token on receiving token expired error
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const refreshToken = getFromLocalStorageWithExpiry({ key: LOCAL_STORAGE_REFRESH_TOKEN });

    const { auth: { auth: { id } } } = store.getState();

    if (refreshToken && id && (error.response && error.response.status === 401 && !originalRequest._retry)) {
      originalRequest._retry = true;

      const { status, data } = await axios.post(to('auth/refresh'), { 
        user_id: id, refresh_token: refreshToken 
      });

      if (status === 200) {
        setAuthTokensToStorage({
          accessToken: data.access_token,
          refreshToken: data.refresh_token
        });

        originalRequest.headers['Authorization'] = data.access_token;
      }

      return axios(originalRequest);
    }

    return Promise.reject(error);
  }
);
