export const SELECT_TILE = "SELECT_TILE";
export const UNSELECT_TILE = "UNSELECT_TILE";
export const RESET_SWAP = "RESET_SWAP";
export const SWAP_TILES = "SWAP_TILE";
export const UPDATE_TILE_STATUS = "UPDATE_TILE_STATUS";

import Img1 from '../assets/images/puzzleGame/dark_country_p2e.webp'
import Img2 from '../assets/images/puzzleGame/racoon_boosters.webp'

export const SET_IMAGE_URL = "SET_IMAGE_URL";

export const UPDATE_GAME_STATUS = "UPDATE_GAME_STATUS";

export const BOARD_SIZE = 570;

export const RESET_GAME = "RESET_GAME";

export const PUZZLE_GAME_IMAGES = [
  // `https://i.picsum.photos/id/650/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=w4DxmOUOGo8-Dvcabm7dR8RS_GWAwb4dsT7PzHYIUYc`,
  // `https://i.picsum.photos/id/650/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=w4DxmOUOGo8-Dvcabm7dR8RS_GWAwb4dsT7PzHYIUYc`,
  // `https://i.picsum.photos/id/941/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=qlrhRQiZvGuZ3QGOsTeirHvQxNLCqe_W1FrvDoDZ8Gg`,
  // `https://i.picsum.photos/id/453/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=64U5vIfRxc60NYm-G2h_ybzPrws986znVWqGpT68WKo`,
  // `https://i.picsum.photos/id/459/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=AUbuHehsWX4XgPCt4R1zyEX9WYLxx2tDFA2cnaiTkBo`,
  // `https://i.picsum.photos/id/158/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=mPll2yfygzLFZeIPyYrStf5v8po9lCFp-g9WL2ZV0cc`,
  // `https://i.picsum.photos/id/983/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=2ZVGJsxuoJBhIuzi8Aq9AKR6r-zxFlTpUFp7fHqDjIE`,
  // `https://i.picsum.photos/id/859/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=8oXNlBIfEX7j8MD5Ir_E5EAJGmX_D915N0DLwGYdaNg`,
  // `https://i.picsum.photos/id/247/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=ur-SrHcqT3Qu3hlq-yfhSVDyw5JAZi3fl_ZdUvI2iFg`,
  // `https://i.picsum.photos/id/80/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=TkNYOqDQtNou4Whtk4h6-zpPZqtqwPmy4tJiWw1RVY4`,
  // `https://i.picsum.photos/id/606/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=uaEIXNwBuXaH2aSjmFB6o3j1y28k-xGs4gvPVgvmA38`,
  // `https://i.picsum.photos/id/233/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=6rZU8NC1dhSWdyRjdXtbm77z9y0hD7ob942s26towJE`,
  // `https://i.picsum.photos/id/661/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=LRhtrXeo-hEkYOvfZRqXQnnldMvDI4_f6Gw00Q4CUbQ`,
  // `https://i.picsum.photos/id/55/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=I7VjY-jxp1amm-0g89bRW2e9hYWzJBdk8mnXTyeYoBM`,
  // `https://i.picsum.photos/id/823/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=MbSdx0yn-e01AWoqYm6G5BanK0uoh1vjRU8SXP3Q9SM`,
  // `https://i.picsum.photos/id/718/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=SDTTO6Ro-iZuImmaMPUq4ravpxAfD33jNk2ZLDD4De0`,
  // `https://i.picsum.photos/id/440/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=dP-py4jFXIWtROGISieUC2Np2QDkRtI-u2hoySWDw5Q`,
  // `https://i.picsum.photos/id/100/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=3fTGTr9C-Mj81-hq88sfko5D9BRhVSDV_c5v2r_xQCg`,
  // `https://i.picsum.photos/id/514/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=2m8ghmGaAkbKjg_1ftZSdnefXP3wwOyeXCvvK3XsM70`,
  // `https://i.picsum.photos/id/512/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=SnKGowrkuHrEY2ZzOuTkgbjounARvIx6HAw3KAt4ExE`,
  // `https://i.picsum.photos/id/455/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=GV5bg3RSzYS21h0nH-zmU191STV2634sXZL4PK8XNCc`,
  // `https://i.picsum.photos/id/564/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=fzkXpWeJcLva2x9Bk3Msn3CFjqrU3rhTQGOqUynIM4s`,
  // `https://i.picsum.photos/id/500/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=ztUzvjHZpuO1uemQqtTMHptyqMQvlT7puoVPH8T__WU`,
  // `https://i.picsum.photos/id/164/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=KveR23SzefGvRuDHHZ1dwfWjfLVkrVRWA9_BcYIcjS0`,
  // `https://i.picsum.photos/id/188/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=hwTgQ9RMjmf0rzvzWzwZYJtS-Taiz4kdjUCpR_sgQgo`,
  // `https://i.picsum.photos/id/699/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=LVMAoV9kb-z25P8Y-PpDM4pqZ6UOd-7D037e3KgJrKs`,
  // `https://i.picsum.photos/id/695/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=hUjuWHSr1EBAc7F6VLmCBf8n36ke5EJP049FzDljkvU`,
  // `https://i.picsum.photos/id/1067/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=horWFrGR2sMNuyUdhdhmYYgBATJ7B2_mrG87JcE5xUo`,
  // `https://i.picsum.photos/id/877/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=lvOmsSzbOaj4RMCeRXWE-TuURAgiPS3taLqzTW3QAtk`,
  // `https://i.picsum.photos/id/882/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=RRL2z5IrNdE3IU4Tc0PsO9BY8FXNUC50vljPXbec9bc`,
  // `https://i.picsum.photos/id/32/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=nMXLH-E7uMJe45fCGSQZk4lnbsUbf4ldeWlyPBQq83E`,
  // `https://i.picsum.photos/id/446/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=tvEc0SVI8Db6-YcmMH1ylBxMrbB5jzSfoWc6_tnnaug`,
  // `https://i.picsum.photos/id/481/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=bBBUSC051nLmaTG0tmvbZ8DTtwbVUHNvY1e_CCLyw4M`,
  // `https://i.picsum.photos/id/512/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=SnKGowrkuHrEY2ZzOuTkgbjounARvIx6HAw3KAt4ExE`,
  // `https://i.picsum.photos/id/479/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=QmQzAts8e0wK5Cblu9-ymG-n59r1OEaJ84nQcFTJpOw`,
  // `https://i.picsum.photos/id/620/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=q-lZh52mJT_Aks2lGIJXLXFjiflq5ecj9HxLbU7LWPk`,
  // `https://i.picsum.photos/id/613/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=Z2xQ-HQPkem7PZ2c71Uxx8h57k0k_7ym3HBQRwcRJDs`,
  // `https://i.picsum.photos/id/755/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=BoBmPuOjluOA46-2w-Nj6IgkETWAZoq7atPm0rFLsYs`,
  // `https://i.picsum.photos/id/910/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=79f-2ZyRusPKlWxXZ4WcgWhwctBzm1QbOoW613cL6q4`,
  // `https://i.picsum.photos/id/678/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=tXlcX7KTdv39_bUR-Ab8IjduU9OkVGIP6x_07AacbuQ`,
  // `https://i.picsum.photos/id/952/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=KtqF5m94UXH4M-cqMa_-H17YzuKWi8oJa80Xx_1g3qU`,
  // `https://i.picsum.photos/id/543/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=Lp4q0r8Jgu4tvqBZrD_-M252tagtKMLk-Q5NscpIvjY`,
  // `https://i.picsum.photos/id/326/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=ebekYOmGcKUVpULsvcE1UEDRh5QDTnYp1MtvLZetKss`,
  // `https://i.picsum.photos/id/795/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=lzWXf0Z6OMjHfusA5VoXlmiS8aejZLlVJb4JOxqkiig`,
  // `https://i.picsum.photos/id/669/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=xtJTpudt1wzZGrUtBN4Zn2iBbgZtFCCxUofw9Mb7B8I`,
  // `https://i.picsum.photos/id/163/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=kg4OvpGy8gaTXDu0RAGNdguEIQ7rTMJxNSf2C1ffZ4g`,
  // `https://i.picsum.photos/id/610/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=CWU3T0u-hnBLT7xHJgQc6szj6ZWaB1IP4fOnZtBVdxo`,
  // `https://i.picsum.photos/id/885/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=78GA92jctQU8rJD9Nq5NH-fn3ZDu6_bbsUHRUeEseSc`,
  // `https://i.picsum.photos/id/95/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=6oVGGS8iFi8-2VemOb-4FOY-CnQB_aAxQaiLGe0JMgc`,
  // `https://i.picsum.photos/id/234/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=sfi9n0AmUu1yXjt9ZYTEW1Uv3v3PpEftqdOObRr3vWw`,
  // `https://i.picsum.photos/id/380/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=8mHNTlZmq6zMzC05dyS98uVeaUvswVSapvmlhECxdQc`,
  // `https://i.picsum.photos/id/283/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=hsla5Kh_wj6ShOjy-fSUE70S1wKu9Wxfi64mnYJYkWY`,
  // `https://i.picsum.photos/id/969/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=XksNct6zARsyOkMobozhSzEl96kJHHrTGkrrm65tsXs`,
  // `https://i.picsum.photos/id/474/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=7rzvywTb8sMvUcWB431TeenatksJvG7fpV0SuI4Y-IQ`,
  // `https://i.picsum.photos/id/979/${BOARD_SIZE}/${BOARD_SIZE}.jpg?hmac=Sjn7hzoqTJTs9Kr10BcF-Ket-Zyls3-arZ8Rtu4MjqA`,
  Img1,
  Img2,
];
