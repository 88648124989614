import { JsonRpc } from "eosjs";
const { deserialize, ObjectSchema } = require("atomicassets");

import { ATOMIC_ASSETS, COLLECT_WHALE } from "../../constants/chain.constants";

const ENDPOINTS = process.env.WAX_CHAIN === 'mainnet'
  ? [
    'https://wax.dapplica.io',
    'https://wax.cryptolions.io',
    'https://api.waxsweden.org',
    'https://wax.greymass.com',
    'https://wax.pink.gg'
  ]
  : ['https://testnet.waxsweden.org']
;

let rpc = new JsonRpc(ENDPOINTS[0], { fetch });

const COLLECT_CONTRACT = 'collectwhale';
const ATOMIC_ASSETS_CONTRACT = 'atomicassets';
const ATOMIC_PACKS_CONTRACT = 'atomicpacksx';

export const claimAllAction = (accountName) => {
  return [
    {
      account: COLLECT_CONTRACT,
      name: 'claimall',
      authorization: [{
        actor: accountName,
        permission: 'active',
      }],
      data: {
        owner: accountName,
      },
    }];
};

export const followFriendAction = (owner, account) => {
  return [
    {
      account: COLLECT_CONTRACT,
      name: 'followfriend',
      authorization: [{
        actor: owner,
        permission: 'active',
      }],
      data: {
        owner,
        account
      },
    }];
};

export const transferAction = ({ owner, from, to, asset_ids, memo }) => {
  return [
    {
      account: ATOMIC_ASSETS_CONTRACT,
      name: 'transfer',
      authorization: [{
        actor: owner,
        permission: 'active',
      }],
      data: {
        from,
        to,
        asset_ids,
        memo
      },
    }];
};

export const transferRacoonTokenAction = ({ owner, from, to, quantity, memo }) => {
  return [
    {
      account: COLLECT_CONTRACT,
      name: 'transfer',
      authorization: [{
        actor: owner,
        permission: 'active',
      }],
      data: {
        from,
        to,
        quantity,
        memo
      },
    }];
};

export const claimUnboxedAction = ({ owner, pack_asset_id, origin_roll_ids }) => {
  return [
    {
      account: ATOMIC_PACKS_CONTRACT,
      name: 'claimunboxed',
      authorization: [{
        actor: owner,
        permission: 'active',
      }],
      data: {
        pack_asset_id,
        origin_roll_ids
      },
    }];
};

export const claimRacoonTokenAction = ({ owner, value }) => {
  return [
    {
      account: COLLECT_CONTRACT,
      name: 'claim',
      authorization: [{
        actor: owner,
        permission: 'active',
      }],
      data: {
        owner,
        coin: `${value.toFixed(4)} RACOON`
      },
    }];
};

//return isNewNetworkExist
const reinitializeRcp = () => {
  const nextEndpoint = ENDPOINTS[ENDPOINTS.indexOf(rpc.endpoint) + 1];

  if (!nextEndpoint)
    return null;

  rpc = new JsonRpc(nextEndpoint ? nextEndpoint : ENDPOINTS[0], { fetch });

  return !!nextEndpoint;
};

export const getAssetByCollectionAndAssetId = async ({ collection, assetId }) => {
  try {
    const { rows } = await rpc.get_table_rows({
      json: true,
      code: ATOMIC_ASSETS_CONTRACT,
      scope: collection,
      table: 'assets',
      limit: 1,
      lower_bound: assetId,
      upper_bound: assetId,
    });

    return rows.find(data => !!data);
  } catch (e) {
    if (!e.message.includes('assertion failure')) {
      const isNewNetworkExist = reinitializeRcp();

      if (!isNewNetworkExist)
        throw new Error('NetworkError!');

      return await getAssetByCollectionAndAssetId({ collection, assetId });
    } else {
      throw new Error(e.message);
    }
  }
};

export const getSchema = async (schemaName) => {
  const { rows } = await rpc.get_table_rows({
    json: true,
    code: ATOMIC_ASSETS,
    scope: COLLECT_WHALE,
    table: 'schemas',
    lower_bound: schemaName,
    limit: 1,
    reverse: false,
    show_payer: false
  });

  const schema = rows.find(data => !!data);

  if (!schema)
    throw new Error('Cannot find schema');

  return schema;
};

export const getTemplateData = async (schemaName, templateId) => {
  const { rows } = await rpc.get_table_rows({
    json: true,
    code: ATOMIC_ASSETS,
    scope: COLLECT_WHALE,
    table: 'templates',
    lower_bound: templateId,
    limit: 1,
    reverse: false,
    show_payer: false,
  });

  const template = rows.find(data => !!data);

  if (!template)
    throw new Error(`Cannot find template - ${templateId}`);

  const schema = await getSchema(schemaName);
  const schemaFormat = ObjectSchema(schema.format);

  return deserialize(template.immutable_serialized_data, schemaFormat);
};

export const fetchRows = async ({ contract, scope, table, limit, lowerBound = null, upperBound = null }) => {
  try {
    const config = {
      json: true,
      code: contract,
      scope,
      table,
      limit,
      lower_bound: lowerBound,
      upper_bound: upperBound
    };

    if (!lowerBound)
      delete config["lower_bound"];

    if (!upperBound)
      delete config["upper_bound"];

    return await rpc.get_table_rows(config);
  } catch (e) {
    if (!e.message.includes('assertion failure')) {
      const isNewNetworkExist = reinitializeRcp();

      if (!isNewNetworkExist)
        throw new Error('NetworkError!');

      return await fetchRows({
        contract, scope, table, limit, lowerBound, upperBound
      });
    } else {
      throw new Error(e.message);
    }
  }
};

export const getTableData = async ({ contract, scope, table }) => {
  const pageSize = 1000;
  let lowerBound = 0;
  let fetchMore = true;

  const assets = [];

  while (fetchMore) {
    // eslint-disable-next-line no-await-in-loop
    const { rows, more, next_key } = await fetchRows({
      contract,
      scope,
      table,
      limit: pageSize,
      lowerBound
    });

    assets.push(...rows);

    if (more)
      lowerBound = next_key;
    else
      fetchMore = false;
  }

  return assets;
};
