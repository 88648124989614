import {
  CHANGE_LANGUAGE,
  LANGUAGE_DEFAULT,
  LANGUAGE_EN,
  LANGUAGE_TR,
  AUTH_LOGOUT_SUCCESS,
} from "../../constants";

const initialState = {
  language: LANGUAGE_DEFAULT,
};

export default function app(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return {
        ...state,
        language: state.language === LANGUAGE_EN ? LANGUAGE_TR : LANGUAGE_EN,
      };

    case AUTH_LOGOUT_SUCCESS:
      return {
        ...state,
      };

    default:
      return state;
  }
}
