import { call, put, all, takeLatest } from "redux-saga/effects";

import { toast } from "react-toastify";

import { Api } from "../../api";
import {
  GET_COLLECTIONS_REQUEST,
  GET_COLLECTIONS_SUCCESS,
  GET_COLLECTIONS_FAIL,

  GET_COLLECTION_BY_ID_REQUEST,
  GET_COLLECTION_BY_ID_SUCCESS,
  GET_COLLECTION_BY_ID_FAIL,

  GET_REWARDED_AUTHORS_SUCCESS,
  GET_REWARDED_AUTHORS_FAIL,

  GET_TOP_AUTHORS_SUCCESS,
  GET_TOP_AUTHORS_FAIL,

  WHITELISTED_COLLECTIONS_REQUEST,
  WHITELISTED_COLLECTIONS_SUCCESS,
  WHITELISTED_COLLECTIONS_FAIL,
} from "../../constants";
import { fetchWhitelistedCollections } from "../../services/gallery.service";

export function* requestGetCollections() {
  try {
    const response = yield call(Api.collections.getCollections);

    const {
      data: {
        collectCollections,
        topRowCollections,
        bottomRowCollections
      }
    } = response;

    yield put({
      type: GET_COLLECTIONS_SUCCESS,
      payload: {
        collectCollections: collectCollections,
        topRowCollections,
        bottomRowCollections
      },
    });
    return response;
  } catch (err) {
    console.log(err);
    yield put({
      type: GET_COLLECTIONS_FAIL,
    });
  }
}

export function* requestGetTopAuthors() {
  try {
    const { data } = yield call(Api.collections.getTopAuthors);

    yield put({
      type: GET_TOP_AUTHORS_SUCCESS,
      payload: data,
    });

    return data;
  } catch (err) {
    console.log(err);
    yield put({
      type: GET_TOP_AUTHORS_FAIL,
    });
  }
}

export function* requestGetRewardedAuthors() {
  try {
    const { data } = yield call(Api.reward.getRewardedAuthors);

    yield put({
      type: GET_REWARDED_AUTHORS_SUCCESS,
      payload: data,
    });

    return data;
  } catch (err) {
    console.log(err);
    yield put({
      type: GET_REWARDED_AUTHORS_FAIL,
    });
  }
}

export function* requestGetCollectionById(action) {
  try {
    const response = yield call(
      Api.collections.getCollectionById,
      action.payload.collectionID
    );

    const { data } = response;

    yield put({
      type: GET_COLLECTION_BY_ID_SUCCESS,
      payload: data[0],
    });
    return response;
  } catch (err) {
    console.log(err);
    yield put({
      type: GET_COLLECTION_BY_ID_FAIL,
    });
  }
}

export function* requestWhitelistedCollections() {
  try {
    const whitelistedCollections = yield call(fetchWhitelistedCollections);

    yield put({
      type: WHITELISTED_COLLECTIONS_SUCCESS,
      payload: whitelistedCollections,
    });
  } catch (e) {
    toast.error(e.message);

    yield put({ type: WHITELISTED_COLLECTIONS_FAIL });
  }
}

function* requestGetCollectionsSaga() {
  yield takeLatest(GET_COLLECTIONS_REQUEST, requestGetCollections);
}

function* requestGetCollectionByIdSaga() {
  yield takeLatest(GET_COLLECTION_BY_ID_REQUEST, requestGetCollectionById);
}

function* requestWhitelistedCollectionsSaga() {
  yield takeLatest(WHITELISTED_COLLECTIONS_REQUEST, requestWhitelistedCollections);
}

export function* getCollectionsSagas() {
  yield all([
    call(requestGetCollectionsSaga),
    call(requestGetCollectionByIdSaga),
    call(requestWhitelistedCollectionsSaga),
  ]);
}
