import { call, put, all, takeEvery } from "redux-saga/effects";

import { Api } from "../../api";
import {
  GET_FRIEND_DETAILS_REQUEST,
  GET_FRIEND_DETAILS_SUCCESS,
  GET_FRIEND_DETAILS_FAIL,
} from "../../constants";

export function* requestFriendDetails(action) {
  try {
    const { data } = yield call(Api.user.getUserData, action.payload);

    yield put({
      type: GET_FRIEND_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: GET_FRIEND_DETAILS_FAIL,
    });
  }
}

export function* requestFriendDetailsSaga() {
  yield takeEvery(GET_FRIEND_DETAILS_REQUEST, requestFriendDetails);
}

export function* friendDetailsSagas() {
  yield all([call(requestFriendDetailsSaga)]);
}
