//canvas pixels, 25 for full gallery, 45 for one hall, 60 for small
export const BLOCK_SIZE = 45;

export const BLOCKS_IN_ROOM_ROW = 5;
export const BLOCKS_IN_ROOM = 25;

export const ROOMS_IN_HALL_ROW = 8;
export const ROOMS_IN_HALL_COLUMN = 6;

export const HALLS_IN_GALLERY_ROW = 1; //4
export const HALLS_IN_GALLERY_COLUMN = 1; //2

//GALLERY WITH
//FULL - 160
//ONE HALL - 40
export const GALLERY_WITH = 40;

//GALLERY HEIGHT
//FULL - 60
//ONE HALL - 30
export const GALLERY_HEIGHT = 30;

export const SPOT_1_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '261513' : '263070';
export const SPOT_2_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '261514' : '263071';
export const SPOT_3_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '261515' : '263072';
export const SPOT_4_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '261516' : '263073';
export const SPOT_5_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '261517' : '263074';
export const SPOT_6_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '261518' : '263075';
export const SPOT_7_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '261519' : '263076';
export const SPOT_8_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '261521' : '263077';
export const SPOT_9_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '261522' : '263078';
export const SPOT_10_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '261523' : '263079';
export const SPOT_11_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '261524' : '263080';
export const SPOT_12_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '261525' : '263081';
export const SPOT_13_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '261580' : '263082';
export const SPOT_14_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '261581' : '263083';
export const SPOT_15_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '261582' : '263084';
export const SPOT_16_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '261583' : '263085';
export const SPOT_17_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '261585' : '263086';
export const SPOT_18_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '261586' : '263087';
export const SPOT_19_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '261587' : '263088';
export const SPOT_20_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '261588' : '263089';
export const SPOT_21_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '261589' : '263090';
export const SPOT_22_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '261590' : '263091';
export const SPOT_23_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '261591' : '263092';
export const SPOT_24_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '261592' : '263093';
export const SPOT_25_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '261593' : '263094';

export const BASIC_BOOSTER_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '390172' : '390178';
export const COMMON_BOOSTER_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '390173' : '306193';
export const UNCOMMON_BOOSTER_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '390175' : '306192';
export const RARE_BOOSTER_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '390176' : '306191';
export const EPIC_BOOSTER_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '390177' : '306190';
export const LEGENDARY_BOOSTER_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '390178' : '306189';
export const MYTHIC_BOOSTER_TEMPLATE_ID = process.env.WAX_CHAIN === 'mainnet' ? '390179' : '306188';

export const DETAILS_BLOCK = 'DETAILS';
export const ON_SALE_BLOCK = 'ON SALE';
export const ON_RENT_BLOCK = 'ON RENT';

export const BOOSTER_STAKING_MIN_PERCENT = 1;
export const BOOSTER_STAKING_MAX_PERCENT = 99;
export const BOOSTER_STAKING_MIN_DAYS = 1;
export const BOOSTER_STAKING_MAX_DAYS = 20;
