import React, { useContext, useEffect, useState, Suspense, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";

import { UALContext } from "ual-reactjs-renderer";
import { toast, ToastContainer } from "react-toastify";

const Home = lazy(() => import("../Home/Home"));
const Collections = lazy(() => import("../Collections/Collections"));
const Profile = lazy(() => import("../Profile/Profile"));
const Leaderboard = lazy(() => import("../Leaderboard/Leaderboard"));
const Wishlist = lazy(() => import("../Wishlist/Wishlist"));
const Footer = lazy(() => import("../../components/Footer/Footer"));
const CardDetails = lazy(() => import("../CardDetails/CardDetails"));
const VerifyAssets = lazy(() => import("../VerifyAssets/VerifyAssets"));
const Referral = lazy(() => import("../Referral/Referral"));
const AuthorsCollections = lazy(() => import("../AuthorsCollections/AuthorsCollections"));
const Packs = lazy(() => import("../Packs/Packs"));
const MyItems = lazy(() => import("../MyItems/MyItems"));
const PuzzleContainer = lazy(() => import("../Puzzle/Puzzle"));
const Voting = lazy(() => import("../Voting/Voting"));
const Advertisement = lazy(() => import("../Advertisement/Advertisement"));
const CreateAdvertisementAccount = lazy(() => import("../Advertisement/CreateAdvertisementAccount/CreateAdvertisementAccount"));
const AdvertisementAccount = lazy(() => import("../Advertisement/AdvertisementAccount/AdvertisementAccount"));
const CreateCampaign = lazy(() => import("../Advertisement/CreateCampaign/CreateCampaign"));
const CreateCampaignItem = lazy(() => import("../Advertisement/CreateCampaignItem/CreateCampaignItem"));
const SingleAdvertisement = lazy(() => import("../Advertisement/SignleAdvertisement/SingleAdvertisement"));
const AuctionsContainer = lazy(() => import("../AuctionsContainer/AuctionsContainer"));
const Auction = lazy(() => import("../Auction/Auction"));
const Gallery = lazy(() => import("../Gallery/Gallery"));
const GalleryItem = lazy(() => import("../GalleryItem/GalleryItem"));
const OnboardingModal = lazy(() => import("../../modals/OnboardingModal/OnboardingModal"));

import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";

import { Api } from "../../api";
import { AUTH_LOGIN_REQUEST, AUTH_PROFILE_REQUEST, AUTH_REGISTER_REQUEST, HOME_LOAD_REQUEST } from "../../constants";
import { LOCAL_STORAGE_REFRESH_TOKEN } from "../../constants/localStorage.constants";
import { getFromLocalStorageWithExpiry } from "../../helpers/storage";
import { getReferralFromCookies } from "../../helpers/referal";

export const routes = [];

export default function App() {
  const { activeUser } = useContext(UALContext);
  const dispatch = useDispatch();
  const history = useHistory();

  const collections = useSelector(({ collections: { collections } }) => collections);

  const [showOnboardingModal, setShowOnboardingModal] = useState(false);
  
  useEffect(() => {
    dispatch({ type: HOME_LOAD_REQUEST });
  }, [dispatch]);

  const handleRegister = (accountName) => {
    const ref = getReferralFromCookies();

    dispatch({
      type: AUTH_REGISTER_REQUEST,
      payload: { accountName, ref }
    });
  };

  const finishRegister = () => {
    setShowOnboardingModal(false);
    history.push(`/profile/${activeUser.accountName}`);
  };

  const handleLogin = (accountName) => {
    dispatch({
      type: AUTH_LOGIN_REQUEST,
      payload: { accountName }
    });
  };

  useEffect(() => {
    if (dispatch && activeUser && activeUser.accountName) {
      const refreshToken = getFromLocalStorageWithExpiry({ key: LOCAL_STORAGE_REFRESH_TOKEN });

      if (refreshToken) {
        dispatch(({
          type: AUTH_PROFILE_REQUEST,
          payload: { accountName: activeUser.accountName }
        }));
      } else {
        Api.user.getUserData({ accountName: activeUser.accountName })
          .then(({ data }) => {
            if (data) {
              handleLogin(activeUser.accountName);
            } else {
              setShowOnboardingModal(true);
              
              handleRegister(activeUser.accountName);
            }
          })
          .catch(e => {
            toast.error(e.response && e.response.data.message ? e.response.data.message : e.message);
          });
      }
    }
  }, [dispatch, activeUser]);

  return (
    <Suspense fallback={<></>}>
      <ScrollToTop />

      <Switch>
        <Route path="/" exact component={Home} />

        <Route path="/collections" exact>
          <Collections collections={collections} slideable={false} />
        </Route>

        <Route path="/authors" exact>
          <Collections collections={collections} slideable={false} showAuthors={true} />
        </Route>

        <Route path="/author/:name" exact>
          <AuthorsCollections collections={collections} slideable={false} />
        </Route>

        <Route path="/profile/:id" exact>
          <Profile />
        </Route>

        <Route path="/leaderboard" exact>
          <Leaderboard homepage={false} />
        </Route>

        <Route path="/collection/:id" exact>
          <CardDetails />
        </Route>

        <Route path="/wishlist" exact>
          <Wishlist />
        </Route>

        <Route path="/verify-assets" exact>
          <VerifyAssets />
        </Route>

        <Route path="/referral" exact>
          <Referral />
        </Route>

        <Route path="/packs" exact>
          <Packs />
        </Route>

        <Route path="/my-items" exact>
          <MyItems />
        </Route>

        <Route path="/puzzle" exact>
          <PuzzleContainer />
        </Route>

        <Route path="/voting" exact>
          <Voting />
        </Route>

        <Route path="/advertisement" component={Advertisement} exact />

        <Route path="/advertisement/create-account" component={CreateAdvertisementAccount} exact />

        <Route path="/advertisement/create-campaign" component={CreateCampaign} exact />

        <Route path="/advertisement/create-item/:campaignID" component={CreateCampaignItem} exact />

        <Route path="/advertisement/profile" component={AdvertisementAccount} exact />

        <Route path="/advertisement/watch" component={SingleAdvertisement} exact />

        <Route path="/auctions" component={AuctionsContainer} exact />
        <Route path="/auction/:id" component={Auction} exact />

        <Route path="/gallery" component={Gallery} exact />
        <Route path="/gallery/:id" component={GalleryItem} exact />

        <Redirect to="/" />
      </Switch>

      <Footer />

      <ToastContainer
        position="bottom-left"
        autoClose={4000}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
      />

      <OnboardingModal
        visible={showOnboardingModal}
        finishLogin={finishRegister}
      />
    </Suspense>
  );
}
