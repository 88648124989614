import {
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAIL,
  AUTH_LOGOUT_SUCCESS,
  AUTH_REGISTER_REQUEST,
  AUTH_REGISTER_SUCCESS,
  AUTH_REGISTER_FAIL,
  AUTH_PROFILE_REQUEST,
  AUTH_PROFILE_FAIL
} from "../../constants";

const initialState = {
  auth: {},
  loggedIn: false,
  loading: false,
  errorText: null,
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case AUTH_LOGIN_REQUEST:
    case AUTH_REGISTER_REQUEST:
    case AUTH_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        loggedIn: true,
        auth: action.payload,
      };

    case AUTH_LOGIN_FAIL:
      return {
        ...state,
        loggedIn: false,
        loading: false,
        errorText: action.payload.error,
      };

    case AUTH_REGISTER_SUCCESS:
    case AUTH_PROFILE_FAIL:
      return {
        ...state,
        loading: false
      };

    case AUTH_REGISTER_FAIL:
      return {
        ...state,
        loading: false,
        errorText: action.payload.error,
      };

    case AUTH_LOGOUT_SUCCESS:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
