export function showDebugData() {
  const messages = [];
  const options = "background: #111; color: #f14668; padding: 8px";

  messages.push(`Environment: ${process.env.NODE_ENV}`);

  messages.forEach((message) => {
    console.log(`%c${message}`, options);
  });
}
