import {
  ATOMIC_ASSETS,
  COLLECT_WHALE,
  GALLERY_SCHEMA,
  NFT_GALLERY_ACCOUNT,
  BOOSTER_SCHEMA
} from "../constants/chain.constants";
import { RACOON, WAX } from "../constants/currency.constants";
import { getTableData, fetchRows } from "../helpers/chain";
import { getDataFromAtomicApi } from "../helpers/atomicApi";

export const fetchGalleryPlaces = async (account) => {
  return await getDataFromAtomicApi(`assets?owner=${account}&schema_name=${GALLERY_SCHEMA}&page=1&limit=1000`);
};

const countRoomOrHallId = (roomOrHallId, roomsOrHallsAmount) => {
  const number = Number(roomOrHallId) / roomsOrHallsAmount;
  const integer = parseInt(number.toString());

  return number > integer ? integer + 1 : integer;
};

export const fetchGalleryData = async () => {
  const assets = (await getTableData({
    contract: NFT_GALLERY_ACCOUNT,
    scope: NFT_GALLERY_ACCOUNT,
    table: 'exhibits'
  }))
    .map(assetData => {
      const roomId = countRoomOrHallId(assetData.place_id, 25);
      const hallId = countRoomOrHallId(roomId, 48);

      return { ...assetData, roomId, hallId };
    });

  const assetsToShowIds = assets
    .filter(({ assetToShow_id }) => !!assetToShow_id)
    .map(({ assetToShow_id }) => assetToShow_id);

  if (assetsToShowIds.length) {
    const data = await getDataFromAtomicApi(`assets?ids=${assetsToShowIds.join(',')}&page=1&limit=1000`);

    return assets
      .map(galleryItem => {
        if (!!galleryItem.assetToShow_id) {
          const find = data.find(assetData => +assetData.asset_id === +galleryItem.assetToShow_id);

          return { ...galleryItem, template: find?.data };
        }

        return galleryItem;
      });
  }

  return assets;
};

export const fetchGalleryMiningData = async () => {
  const { rows } = await fetchRows({
    contract: NFT_GALLERY_ACCOUNT,
    scope: NFT_GALLERY_ACCOUNT,
    table: 'mining',
    limit: 1
  });

  const miningData = rows.find(data => !!data);

  if (!miningData)
    return miningData;

  const [nextDaySupply] = miningData.next_day_supply.split(' ');

  return {
    nextDaySupply: `${parseInt(nextDaySupply)} ${RACOON}`,
    dailyIncrease: `${miningData.daily_increase}%`
  };
};

export const fetchStakedBoosters = async (placeId) => {
  const { rows } = await fetchRows({
    contract: NFT_GALLERY_ACCOUNT,
    scope: NFT_GALLERY_ACCOUNT,
    table: 'rigstaked',
    limit: 1,
    lowerBound: placeId,
    upperBound: placeId
  });

  if (!rows[0])
    return [];

  let stakedBoosters = [
    ...rows[0].staked_rigs.map(booster => ({ ...booster, isShared: false })),
    ...rows[0].shared_rigs.map(boosterID => ({ asset_id: boosterID, isShared: true })),
  ];

  if (rows[0].shared_rigs.length) {
    const requests = await getTableData({
      contract: NFT_GALLERY_ACCOUNT,
      scope: placeId,
      table: 'rigstakedreq'
    });

    stakedBoosters = stakedBoosters.map(booster => {
      if (booster.isShared) {
        const found = requests.find(({ rig_asset_id }) => rig_asset_id === booster.asset_id);

        return { ...booster, ...found };
      }

      return booster;
    });
  }

  const data = await getDataFromAtomicApi(`assets?ids=${stakedBoosters.map(({ asset_id }) => asset_id).join(',')}&page=1&limit=1000`);

  return stakedBoosters.map(stakedBooster => {
    return { ...stakedBooster, template: data.find(({ asset_id }) => asset_id === stakedBooster.asset_id)?.template };
  });
};

export const fetchSpotMutableData = async (placeAssetId) => {
  const data = await getDataFromAtomicApi(`assets?ids=${placeAssetId}&page=1&limit=1`);

  return data[0]?.data;
};

export const fetchNftOnSalePrice = async (placeId) => {
  const { rows } = await fetchRows({
    contract: NFT_GALLERY_ACCOUNT,
    scope: NFT_GALLERY_ACCOUNT,
    table: 'shop',
    limit: 1,
    lowerBound: placeId,
    upperBound: placeId
  });

  if (rows[0]) {
    //10.00000000 WAX / RACOON
    const [price, currency] = rows[0].price.split(' ');

    return `${parseFloat(price)} ${currency}`;
  }

  return 0;
};

export const fetchRentInfo = async (placeId) => {
  const { rows } = await fetchRows({
    contract: NFT_GALLERY_ACCOUNT,
    scope: NFT_GALLERY_ACCOUNT,
    table: 'rent',
    limit: 1,
    lowerBound: placeId,
    upperBound: placeId
  });

  const rendData = rows.find(data => !!data);
  const [price, currency] = rendData ? rendData.price.split(' ') : [0, ''];

  return {
    rentPrice: `${parseFloat(price)} ${currency}`,
    rentTime: rendData?.time_forRent,
    tenant: rendData?.tenant,
    endTenantTimeRent: rendData?.endTenantTime_rent
  };
};

const fetchRewardedViewers = async (placeId) => {
  const { rows } = await fetchRows({
    contract: NFT_GALLERY_ACCOUNT,
    scope: NFT_GALLERY_ACCOUNT,
    table: 'rewardviewer',
    limit: 1,
    lowerBound: placeId,
    upperBound: placeId
  });

  return rows[0] ? rows[0].viewers : [];
};

export const fetchGalleryItemData = async (placeId) => {
  const { rows } = await fetchRows({
    contract: NFT_GALLERY_ACCOUNT,
    scope: NFT_GALLERY_ACCOUNT,
    table: 'exhibits',
    limit: 1,
    lowerBound: placeId,
    upperBound: placeId
  });

  const itemData = rows.find(data => !!data);

  if (!itemData)
    return null;

  const roomId = countRoomOrHallId(itemData.place_id, 25);
  const hallId = countRoomOrHallId(roomId, 48);

  const galleryItemData = {
    ...itemData,
    hallId,
    roomId,
    stakedBoosters: await fetchStakedBoosters(placeId),
    spotMutableData: await fetchSpotMutableData(itemData.place_asset_id),
    viewers: await fetchRewardedViewers(placeId)
  };

  if (!!galleryItemData.assetToShow_id) {
    const { data } = await getDataFromAtomicApi(`assets/${galleryItemData.assetToShow_id}`);

    galleryItemData.template = data;
  }

  if (!!galleryItemData.onSale) {
    galleryItemData.price = await fetchNftOnSalePrice(placeId);
  }

  if (!!galleryItemData.onRent) {
    const { rentPrice, rentTime, tenant, endTenantTimeRent } = await fetchRentInfo(placeId);

    galleryItemData.rentPrice = rentPrice;
    galleryItemData.rentTime = rentTime;
    galleryItemData.tenant = tenant;
    galleryItemData.endTenantTimeRent = endTenantTimeRent;
  }

  const { rows: stakedRows } = await fetchRows({
    contract: NFT_GALLERY_ACCOUNT,
    scope: NFT_GALLERY_ACCOUNT,
    table: 'staked',
    limit: 1,
    lowerBound: placeId,
    upperBound: placeId
  });

  const itemStakedData = stakedRows.find(data => !!data);

  if (itemStakedData) {
    const [stakedTokens, currency] = itemStakedData.staked_tokens.split(' ');

    galleryItemData.stakedTokens = `${parseInt(stakedTokens)} ${currency}`;
  }

  return galleryItemData;
};

export const fetchOffersToBuyNft = async ({ owner, assetIdToBuy }) => {
  const { rows } = await fetchRows({
    contract: NFT_GALLERY_ACCOUNT,
    scope: owner,
    table: 'offers2buy',
    limit: 1,
    lowerBound: assetIdToBuy,
    upperBound: assetIdToBuy
  });

  const offersData = rows.find(data => !!data);

  return offersData
      ? offersData.buyers
        .map(({ user, offered_price }) => {
          //10.00000000 WAX / RACOON
          const [price, currency] = offered_price.split(' ');

          return {
            account: user,
            price: `${parseFloat(price)} ${currency}`
          };
        })
      : [];
};

export const fetchOffersToRent = async ({ owner, placeId }) => {
  const { rows } = await fetchRows({
    contract: NFT_GALLERY_ACCOUNT,
    scope: owner,
    table: 'offers2rent',
    limit: 1,
    lowerBound: placeId,
    upperBound: placeId
  });

  const offersData = rows.find(data => !!data);

  return offersData
      ? offersData.tenants
        .map(({ user, offered_price, time_minute }) => {
          //10.00000000 WAX / RACOON
          const [price, currency] = offered_price.split(' ');

          return {
            account: user,
            rentPrice: `${parseFloat(price)} ${currency}`,
            rentTime: time_minute
          };
        })
      : [];
};

export const fetchLockedTokens = async (account) => {
  return await getTableData({
    contract: NFT_GALLERY_ACCOUNT,
    scope: account,
    table: 'unlocktoken',
  });
};

export const fetchGalleryBalance = async (account) => {
  const { rows } = await fetchRows({
    contract: NFT_GALLERY_ACCOUNT,
    scope: account,
    table: 'accounts',
    limit: 1
  });

  return rows
    .map(({ balance_racoon, balance_wax }) => {
      return {
        balance_racoon: parseFloat(balance_racoon.split(' ')[0]),
        balance_wax: parseFloat(balance_wax.split(' ')[0]),
        //balance_collect: parseFloat(balance_collect.split(' ')[0]),
        balance_collect: 0,
      };
    })
    .find(data => !!data);
};

export const fetchRequestToContinueRent = async (placeId) => {
  const { rows } = await fetchRows({
    contract: NFT_GALLERY_ACCOUNT,
    scope: NFT_GALLERY_ACCOUNT,
    table: 'requests',
    limit: 1,
    lowerBound: placeId,
    upperBound: placeId
  });

  return rows.find(data => !!data);
};

export const fetchGalleryItemVisitors = async (placeId) => {
  const { rows } = await fetchRows({
    contract: NFT_GALLERY_ACCOUNT,
    scope: NFT_GALLERY_ACCOUNT,
    table: 'rewardviewer',
    limit: 1,
    lowerBound: placeId,
    upperBound: placeId
  });

  return rows.find(data => !!data)?.viewers ?? [];
};

export const fetchBoosters = async ({ owner }) => {
  const data = await getDataFromAtomicApi(`assets?collection_name=${COLLECT_WHALE}&schema_name=${BOOSTER_SCHEMA}&owner=${owner}&page=1&limit=1000`);

  return data.map(({ asset_id, template }) => ({ asset_id, template }));
};

export const fetchBorderConfig = async () => {
  const { rows } = await fetchRows({
    contract: NFT_GALLERY_ACCOUNT,
    scope: NFT_GALLERY_ACCOUNT,
    table: 'borderconf',
  });

  return rows;
};

export const fetchSlotsConfig = async () => {
  const { rows } = await fetchRows({
    contract: NFT_GALLERY_ACCOUNT,
    scope: NFT_GALLERY_ACCOUNT,
    table: 'slotsconf',
  });

  return rows;
};

export const fetchBoostersConfig = async () => {
  const { rows } = await fetchRows({
    contract: NFT_GALLERY_ACCOUNT,
    scope: NFT_GALLERY_ACCOUNT,
    table: 'boosterconf',
  });

  return rows;
};

export const fetchRequestsForBoostersStake = async ({ placeId }) => {
  const requests = (await getTableData({
    contract: NFT_GALLERY_ACCOUNT,
    scope: placeId,
    table: 'rigstakedreq'
  }))
    .filter(({ end_time }) => !!!end_time);

  const data = await getDataFromAtomicApi(`assets?ids=${requests.map(({ rig_asset_id }) => rig_asset_id).join(',')}&page=1&limit=1000`);

  return requests.map(request => {
    return { ...request, template: data.find(({ asset_id }) => asset_id === request.rig_asset_id)?.template };
  });
};

export const fetchWhitelistedCollections = async () => {
  const { rows } = await fetchRows({
    contract: 'atomhubtools',
    scope: 'atomhubtools',
    table: 'acclists',
    limit: 1,
    lowerBound: 'col.wlist',
    upperBound: 'col.wlist'
  });

  return rows[0] ? rows[0].list : [];
};

export const fetchMaxViewers = async () => {
  const data = await getTableData({
    contract: NFT_GALLERY_ACCOUNT,
    scope: NFT_GALLERY_ACCOUNT,
    table: 'maxviewers'
  });

  return data[0] && data[0].max_viewers ? data[0].max_viewers : 0;
};

//GALLERY ACTIONS
const signTransaction = async ({ activeUser, account, action, data }) => {
  await activeUser.signTransaction({
    actions: [
      {
        account,
        name: action,
        authorization: [{
          actor: activeUser.accountName,
          permission: 'active',
        }],
        data
      }
    ]
  }, {
    blocksBehind: 3,
    expireSeconds: 30
  });
};

export const stakePlace = async ({ activeUser, owner, assetId }) => {
  await signTransaction({
    activeUser,
    account: ATOMIC_ASSETS,
    action: 'transfer',
    data: {
      from: owner,
      to: NFT_GALLERY_ACCOUNT,
      asset_ids: [Number(assetId)],
      memo: "stake place",
    }
  });
};

export const unstakePlace = async ({ activeUser, owner, placeId }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'unstakeplace',
    data: {
      owner,
      place_id: placeId
    }
  });
};

export const stakeNft = async ({ activeUser, assetId, placeId }) => {
  await signTransaction({
    activeUser,
    account: ATOMIC_ASSETS,
    action: 'transfer',
    data: {
      from: activeUser.accountName,
      to: NFT_GALLERY_ACCOUNT,
      asset_ids: [Number(assetId)],
      memo: placeId.toString(),
    }
  });
};

export const unstakeNft = async ({ activeUser, owner, placeId }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'unstakenft',
    data: {
      owner,
      place_id: placeId
    },
  });
};

export const sellNft = async ({ activeUser, owner, placeId, assetToSellId, price, currency }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'sellnft',
    data: {
      owner,
      place_id: placeId,
      assetToSell_id: assetToSellId,
      price: `${Number(price).toFixed(currency === RACOON ? 4 : 8)} ${currency}`
    },
  });
};

export const cancelSale = async ({ activeUser, owner, id }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'cancelsale',
    data: { owner, id }
  });
};

export const acceptOffer = async ({ activeUser, owner, buyer, placeId }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'acceptoffer',
    data: { owner, buyer, place_id: placeId }
  });
};

export const rejectOffer = async ({ activeUser, owner, buyer, placeId }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'rejectoffer',
    data: { owner, buyer, place_id: placeId }
  });
};

export const rejectAllOffers = async ({ activeUser, owner, placeId }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'rejalloffers',
    data: { owner, place_id: placeId }
  });
};

export const buyNft = async ({ activeUser, owner, buyer, goodsId }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'buynft',
    data: { owner, buyer, goods_id: goodsId }
  });
};

export const offerPrice = async ({ activeUser, owner, buyer, price, placeId, currency }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'offerprice',
    data: {
      owner,
      buyer,
      price: `${Number(price).toFixed(currency === RACOON ? 4 : 8)} ${currency}`,
      place_id: placeId
    }
  });
};

export const cancelOfferPrice = async ({ activeUser, owner, buyer, assetId }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'cancelofferb',
    data: { owner, buyer, asset_id: assetId }
  });
};

export const deposit = async ({ activeUser, owner, quantity, currency }) => {
  await signTransaction({
    activeUser,
    account: currency === WAX ? 'eosio.token' : COLLECT_WHALE,
    action: 'transfer',
    data: {
      from: owner,
      to: NFT_GALLERY_ACCOUNT,
      quantity: `${Number(quantity).toFixed(currency === RACOON ? 4 : 8)} ${currency}`,
      memo: 'deposit',
    },
  });
};

export const withdraw = async ({ activeUser, owner, quantity }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'withdraw',
    data: {
      withdraw_account: owner,
      quantity
    },
  });
};

export const rentOut = async ({ activeUser, owner, placeId, timeMinute, price, currency }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'rentout',
    data: {
      owner,
      place_id: placeId,
      time_minute: Number(timeMinute),
      price: `${Number(price).toFixed(currency === RACOON ? 4 : 8)} ${currency}`
    },
  });
};

export const cancelRent = async ({ activeUser, owner, placeId }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'cancelrent',
    data: { owner, place_id: placeId },
  });
};

export const kickTenant = async ({ activeUser, owner, placeId, cancelRent }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'kicktenant',
    data: { owner, place_id: placeId, cancelrent: cancelRent },
  });
};

export const rejectCont = async ({ activeUser, owner, placeId }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'rejectcont',
    data: { owner, place_id: placeId },
  });
};

export const acceptCont = async ({ activeUser, owner, tenant, placeId }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'acceptcont',
    data: { owner, tenant, place_id: placeId },
  });
};

export const acceptRent = async ({ activeUser, owner, tenant, placeId }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'acceptrent',
    data: { owner, tenant, place_id: placeId },
  });
};

export const rejectRent = async ({ activeUser, owner, tenant, placeId }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'rejectrent',
    data: { owner, tenant, place_id: placeId },
  });
};

export const rejectAllRent = async ({ activeUser, owner, placeId }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'rejallrent',
    data: { owner, place_id: placeId },
  });
};

export const offerRent = async ({ activeUser, owner, tenant, placeId, price, currency, timeMinute }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'offerrent',
    data: {
      tenant,
      price: `${Number(price).toFixed(currency === RACOON ? 4 : 8)} ${currency}`,
      time_minute: timeMinute,
      owner,
      place_id: placeId
    },
  });
};

export const rentPlace = async ({ activeUser, owner, tenant, placeId }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'rentplace',
    data: {
      tenant,
      owner,
      place_id: placeId
    },
  });
};

export const cancelCont = async ({ activeUser, tenant, placeId }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'cancelcont',
    data: {
      tenant,
      place_id: placeId
    },
  });
};

export const cancelRentOffer = async ({ activeUser, owner, tenant, placeId }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'cancelrentoff',
    data: {
      tenant,
      owner,
      place_id: placeId
    },
  });
};

export const stakeRacoons = async ({ activeUser, owner, quantity, placeId }) => {
  await signTransaction({
    activeUser,
    account: COLLECT_WHALE,
    action: 'transfer',
    data: {
      from: owner,
      to: NFT_GALLERY_ACCOUNT,
      quantity: `${Number(quantity).toFixed(4)} ${RACOON}`,
      memo: `stake: ${placeId}`,
    },
  });
};

export const unstakeRacoons = async ({ activeUser, placeId, quantity }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'unstaketoken',
    data: {
      owner: activeUser.accountName,
      place_id: +placeId,
      quantity_to_unstake: `${Number(quantity).toFixed(4)} ${RACOON}`,
    },
  });
};

export const upgradeSlots = async ({ activeUser, owner, placeId }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'upgradeslots',
    data: { owner, place_id: placeId },
  });
};

export const upgradeBorder = async ({ activeUser, owner, placeId }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'upgrborder',
    data: { owner, place_id: placeId },
  });
};

export const stakeBooster = async ({ activeUser, assetId, placeId }) => {
  await signTransaction({
    activeUser,
    account: ATOMIC_ASSETS,
    action: 'transfer',
    data: {
      from: activeUser.accountName,
      to: NFT_GALLERY_ACCOUNT,
      asset_ids: [Number(assetId)],
      memo: `rig: ${placeId}`
    }
  });
};

export const stakeBoosterToNotOwnSpot = async ({ activeUser, assetId, placeId, days, interestShare }) => {
  await signTransaction({
    activeUser,
    account: ATOMIC_ASSETS,
    action: 'transfer',
    data: {
      from: activeUser.accountName,
      to: NFT_GALLERY_ACCOUNT,
      asset_ids: [Number(assetId)],
      //request_rig:435:12:70   (place_id, days, interest_share)
      memo: `request_rig:${placeId}:${days}:${interestShare}`
    }
  });
};

export const unstakeBooster = async ({ activeUser, placeId, boosts }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'unstakeboost',
    data: { owner: activeUser.accountName, place_id: placeId, boosts },
  });
};

export const acceptBoost = async ({ activeUser, placeId, boostAssetId }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'acceptboost',
    data: { owner: activeUser.accountName, place_id: placeId, boost_asset_id: boostAssetId }
  });
};

export const cancelBoost = async ({ activeUser, placeId, boostAssetId }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'cancelboost',
    data: { place_id: placeId, boost_asset_id: boostAssetId }
  });
};

export const rejectBoost = async ({ activeUser, placeId, boostAssetId }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'rejectboost',
    data: { owner: activeUser.accountName, place_id: placeId, boost_asset_id: boostAssetId }
  });
};

export const rejectAllBoosts = async ({ activeUser, placeId }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'rejallboost',
    data: { owner: activeUser.accountName, place_id: placeId }
  });
};

export const claimBoost = async ({ activeUser, placeId, boostAssetId }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'claimboost',
    data: { place_id: placeId, boost_asset_id: boostAssetId }
  });
};

export const claimOnSpot = async ({ activeUser, placeId }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'claim',
    data: {
      owner: activeUser.accountName,
      place_id: placeId
    }
  });
};

export const unlockTokens = async ({ activeUser, lockingId }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'unlocktokens',
    data: {
      owner: activeUser.accountName,
      locking_id: lockingId
    }
  });
};

export const boostShare = async ({ activeUser, placeId, newBoostShare }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'boostshare',
    data: {
      owner: activeUser.accountName,
      place_id: placeId,
      new_boost_share: newBoostShare
    }
  });
};

export const updateViewerReward = async ({ activeUser, placeId, newViewerReward }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'viewereward',
    data: {
      owner: activeUser.accountName,
      place_id: placeId,
      new_viewer_reward: newViewerReward
    }
  });
};

export const visitSpotGame = async ({ activeUser }) => {
  await signTransaction({
    activeUser,
    account: NFT_GALLERY_ACCOUNT,
    action: 'visit',
    data: { user: activeUser.accountName }
  });
};
