import {
  USER_DATA_REQUEST,
  USER_DATA_SUCCESS,
  USER_DATA_FAIL,
  USER_COLLECTIONS_REQUEST,
  USER_COLLECTIONS_SUCCESS,
  USER_COLLECTIONS_FAIL,
  USER_ACHIEVEMENTS_REQUEST,
  USER_ACHIEVEMENTS_SUCCESS,
  USER_ACHIEVEMENTS_FAIL,
  USER_BADGES_REQUEST,
  USER_BADGES_SUCCESS,
  USER_BADGES_FAIL,
  USER_FRIENDS_REQUEST,
  USER_FRIENDS_SUCCESS,
  USER_FRIENDS_FAIL,
  GET_FRIEND_DETAILS_REQUEST,
  GET_FRIEND_DETAILS_SUCCESS,
  GET_FRIEND_DETAILS_FAIL,
  USER_REWARD_COUNTER_REQUEST,
  USER_REWARD_COUNTER_SUCCESS,
  USER_REWARD_COUNTER_FAIL,
  AUTH_LOGOUT_SUCCESS
} from "../../constants";

const initialState = {
  userData: {},
  userCollections: [],
  userAchievements: [],
  userInvites: [],
  userBadges: [],
  userFriends: [],
  friendDetails: [],
  loading: false,
  rewardCounter: null
};

export default function userData(state = initialState, action) {
  switch (action.type) {
    case USER_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: action.payload,
      };
    case USER_DATA_FAIL:
      return {
        ...state,
        loading: false,
        userData: {},
      };

    case USER_COLLECTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        userCollections: [],
      };
    case USER_COLLECTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        userCollections: [...action.payload],
      };
    case USER_COLLECTIONS_FAIL:
      return {
        ...state,
        loading: false,
        userCollections: [],
      };

    case USER_ACHIEVEMENTS_REQUEST:
      return {
        ...state,
        loading: true,
        userAchievements: [],
        userInvites: []
      };
    case USER_ACHIEVEMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        userAchievements: [...action.payload.achievements],
        userInvites: [...action.payload.invitedUsers]
      };
    case USER_ACHIEVEMENTS_FAIL:
      return {
        ...state,
        loading: false,
        userAchievements: [],
        userInvites: []
      };

    case USER_BADGES_REQUEST:
      return {
        ...state,
        loading: true,
        userBadges: [],
      };
    case USER_BADGES_SUCCESS:
      return {
        ...state,
        loading: false,
        userBadges: [action.payload],
      };
    case USER_BADGES_FAIL:
      return {
        ...state,
        loading: false,
        userBadges: [],
      };

    case USER_FRIENDS_REQUEST:
      return {
        ...state,
        loading: true,
        userFriends: [],
      };
    case USER_FRIENDS_SUCCESS:
      return {
        ...state,
        loading: false,
        userFriends: [...action.payload],
      };
    case USER_FRIENDS_FAIL:
      return {
        ...state,
        loading: false,
        userFriends: [],
      };

    case GET_FRIEND_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        friendDetails: [],
      };
    case GET_FRIEND_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        friendDetails: [...state.friendDetails, ...action.payload],
      };
    case GET_FRIEND_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        friendDetails: [],
      };

    case USER_REWARD_COUNTER_REQUEST:
      return {
        ...state,
        loading: true,
        rewardCounter: null
      };

    case USER_REWARD_COUNTER_SUCCESS:
      return {
        ...state,
        loading: false,
        rewardCounter: action.payload
      };

    case USER_REWARD_COUNTER_FAIL:
      return {
        ...state,
        loading: false,
        rewardCounter: null
      };

    case AUTH_LOGOUT_SUCCESS:
      return {
        ...state,
        rewardCounter: null,
      };

    default:
      return state;
  }
}
