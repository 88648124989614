import { call, put, all, takeLatest } from "redux-saga/effects";

import { Api } from "../../api";
import {
  HOME_LOAD_REQUEST,
  HOME_LOAD_SUCCESS,
  HOME_LOAD_FAIL,
} from "../../constants";

import { requestGetCollections, requestGetTopAuthors, requestGetRewardedAuthors } from "./collections.sagas";
import { requestGetLeaderboard } from "./leaderboard.sagas";
import { requestGetNews } from "./news.sagas";

function* homeLoadRequest() {
  try {
    const leaderboard = yield* requestGetLeaderboard();
    const newsFeed = yield* requestGetNews();
    const topAuthors = yield* requestGetTopAuthors();
    const rewardedAuthors = yield* requestGetRewardedAuthors();

    const { data } = yield call(Api.homestats.getNestStats);

    if (data && leaderboard && newsFeed && topAuthors) {
      yield put({
        type: HOME_LOAD_SUCCESS,
        payload: {
          homepageData: data,
          topAuthors: topAuthors,
          rewardedAuthors: rewardedAuthors,
          leaderboard: [...leaderboard],
          newsFeed: newsFeed
        },
      });
    } else {
      yield put({
        type: HOME_LOAD_FAIL,
      });
    }
  } catch (err) {
    yield put({
      type: HOME_LOAD_FAIL,
      payload: [],
    });
  } finally {
    yield* requestGetCollections();
  }
}

function* homeLoadRequestSaga() {
  yield takeLatest(HOME_LOAD_REQUEST, homeLoadRequest);
}

export function* homeSagas() {
  yield all([call(homeLoadRequestSaga)]);
}
