import axios from "axios";

import { to } from "../../helpers/api";

export const gallery = {
  setupGalleryGame: ({ account, placeID, image }) => {
    return axios.post(to('gallery/game/setup'), { account, placeID, image });
  },

  verifyGalleryGameResult: ({ hashedSting }) => {
    return axios.post(to('gallery/game/verify'), { hashedSting });
  }
};
