import { call, put, all, takeLatest } from "redux-saga/effects";

import { toast } from "react-toastify";

import { Api } from "../../api";
import {
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAIL,
  AUTH_REGISTER_REQUEST,
  AUTH_REGISTER_SUCCESS,
  AUTH_REGISTER_FAIL,
  AUTH_LOGOUT_REQUEST,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGOUT_FAIL,
  AUTH_PROFILE_REQUEST,
  AUTH_PROFILE_FAIL
} from "../../constants";
import {
  LOCAL_STORAGE_ACCESS_TOKEN,
  LOCAL_STORAGE_REFRESH_TOKEN
} from "../../constants/localStorage.constants";
import { setAuthTokensToStorage } from "../../helpers/auth";
import { encrypt } from "../../helpers/encrypt";

function* login(action) {
  try {
    const { accountName } = action.payload;

    const signature = encrypt(accountName);

    const {
      data: { access_token, refresh_token }
    } = yield call(Api.auth.login, { accountName, signature });

    setAuthTokensToStorage({
      accessToken: access_token,
      refreshToken: refresh_token
    });

    const auth = yield call(Api.user.getUserData, { accountName });

    yield put({ type: AUTH_LOGIN_SUCCESS, payload: auth.data });
  } catch (e) {
    const error = e.response && e.response.data.message ? e.response.data.message : "Bad things happen";

    yield put({ type: AUTH_LOGIN_FAIL, payload: { error:  error } });

    toast.error(error);
  }
}

function* register(action) {
  try {
    const { accountName, ref } = action.payload;

    yield call(Api.auth.register, { accountName, ref });

    yield put({ type: AUTH_REGISTER_SUCCESS });

    yield put({ type: AUTH_LOGIN_REQUEST, payload: { accountName } });
  } catch (e) {
    const error = e.response && e.response.data.message ? e.response.data.message : "Bad things happen";

    yield put({ type: AUTH_REGISTER_FAIL, payload: { error:  error } });

    toast.error(error);
  }
}

function* logout(action) {
  try {
    const { user_id } = action.payload;

    localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);

    yield put({ type: AUTH_LOGOUT_SUCCESS });

    yield call(Api.auth.logout, { user_id });
  } catch (e) {
    const error = e.response && e.response.data.message ? e.response.data.message : "Bad things happen";

    yield put({ type: AUTH_LOGOUT_FAIL, payload: { error:  error } });

    toast.error(error);
  }
}

function* getAuthProfile(action) {
  try {
    const { accountName } = action.payload;
    
    const { data } = yield call(Api.user.getUserData, { accountName });

    yield put({ type: AUTH_LOGIN_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: AUTH_PROFILE_FAIL });
  }
}

function* loginSaga() {
  yield takeLatest(AUTH_LOGIN_REQUEST, login);
}

function* registerSaga() {
  yield takeLatest(AUTH_REGISTER_REQUEST, register);
}

function* logoutSaga() {
  yield takeLatest(AUTH_LOGOUT_REQUEST, logout);
}

function* getAuthProfileSaga() {
  yield takeLatest(AUTH_PROFILE_REQUEST, getAuthProfile);
}

export function* authSagas() {
  yield all([
    call(loginSaga), 
    call(registerSaga), 
    call(logoutSaga),
    call(getAuthProfileSaga),
  ]);
}
