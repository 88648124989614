import {
  LOCAL_STORAGE_ACCESS_TOKEN,
  LOCAL_STORAGE_REFRESH_TOKEN
} from "../../constants/localStorage.constants";
import { setToLocalStorageWithExpiry } from "../storage";

export const setAuthTokensToStorage = ({ accessToken, refreshToken }) => {
  setToLocalStorageWithExpiry({
    key: LOCAL_STORAGE_ACCESS_TOKEN,
    value: accessToken,
    //12h
    ttl: 43200 * 1000
  });

  setToLocalStorageWithExpiry({
    key: LOCAL_STORAGE_REFRESH_TOKEN,
    value: refreshToken,
    //24h
    ttl: 86400 * 1000
  });
};
