import { call, put, all, takeLatest } from "redux-saga/effects";

import { Api } from "../../api";
import {
  GET_NEWS_REQUEST,
  GET_NEWS_SUCCESS,
  GET_NEWS_FAIL
} from "../../constants";

export function* requestGetNews() {
  try {
    const response = yield call(Api.news.getNews);

    const { data } = response;

    yield put({
      type: GET_NEWS_SUCCESS,
      payload: data
    });

    return response;

  } catch (err) {
    yield put({
      type: GET_NEWS_FAIL
    });
  }
}

function* requestGetNewsSaga() {
  yield takeLatest(GET_NEWS_REQUEST, requestGetNews);
}

export function* getNewsSaga() {
  yield all([
    call(requestGetNewsSaga)
  ]);
}
