import axios from "axios";

import { to } from "../../helpers/api";

export const auth = {
  login: ({ accountName, signature }) => {
    return axios.post(to("auth/signin"), {
      account: accountName,
      signature
    });
  },

  register: ({ accountName, ref }) => {
    return axios.post(to("auth/signup"), {
      account: accountName,
      ref
    });
  },

  logout: ({ user_id, account }) => {
    return axios.post(to("auth/signout"), { user_id, account });
  }
};
