import ecc from 'eosjs-ecc';

const SERVER_PUBLIC_KEY = 'EOS8PD4MVvanu3dMgPtrw97SE89gbCBYVfYdtzcfeTBJ5zw9ASCHH';

const base64 = {
  decode: s => Uint8Array.from(atob(s), c => c.charCodeAt(0)),
  encode: b => btoa(String.fromCharCode(...new Uint8Array(b)))
};

function initKeys(key) {
  const privateEncryptionKey = ecc.PrivateKey.fromSeed(key);
  const privateKey = privateEncryptionKey.toString();

  const publicEncryptionKey = privateEncryptionKey.toPublic();
  const publicKey = publicEncryptionKey.toString().replace('EOS', '');

  return { privateKey, publicKey };
}

export function encrypt(data) {
  const keys = initKeys(data);
  const encryptStr = ecc.Aes.encrypt(keys.privateKey, SERVER_PUBLIC_KEY, data.toString());

  return `${base64.encode(encryptStr.message)},${encryptStr.nonce.low},${encryptStr.nonce.high},${encryptStr.checksum},${keys.publicKey}`;
}
