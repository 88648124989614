import axios from "axios";

import { to } from "../../helpers/api";

export const user = {
  getUserData: (data) => {
    return axios.get(to(`user/${data.accountName}`));
  },

  checkInviteCode: ({ account, inviteCode }) => {
    return axios.post(to(`invite-code/check`), { account, inviteCode });
  },

  getUserCollections: (data) => {
    return axios.get(to(`user/collections/${data.accountName}`));
  },

  getUsersAchievements: (data) => {
    return axios.get(to(`user/achievements/${data.accountName}`));
  },

  getUsersBadges: (data) => {
    return axios.get(to(`user/badges/${data.accountName}`));
  },

  getUsersFriends: (data) => {
    return axios.get(to(`user/friends/${data.accountName}`));
  },

  getUsersWishList: (accountName) => {
    return axios.get(to(`user/wishlist/${accountName}`));
  },

  updateFriends: ({ id, friends_ids }) => {
    return axios.put(to(`user/${id}`), { friends_ids });
  },

  updateWishList: ({ id, wishList }) => {
    return axios.put(to(`user/${id}`), { wishList });
  },

  updateAchievementsToShow: ({ id, achievementsToShow }) => {
    return axios.put(to(`user/achievements-to-show/${id}`), { achievementsToShow });
  },

  updateLikes: ({ id, likedCollections, collectionId, likes }) => {
    return axios.put(to(`user/collections-likes/${id}`), { likedCollections, collectionId, likes });
  },

  checkAvailableCollectionsForClaim: ({ id, account, collect_collection_id }) => {
    return axios.post(to('user/check-available-collections'), { id, account, collect_collection_id });
  },

  updateEmail: ({ id, email }) => {
    return axios.put(to(`user/email/${id}`), { email });
  }
};
