import { assets } from "./drivers/assets";
import { auth } from "./drivers/auth";
import { user } from "./drivers/user";
import { collections } from "./drivers/collections";
import { gallery } from "./drivers/gallery";
import { leaderboard } from "./drivers/leaderboard";
import { news } from "./drivers/news";
import { homeStats } from "./drivers/homestats";
import { reward } from "./drivers/reward";

import './axios-config';

export const Api = {
  assets,
  auth,
  user,
  collections,
  gallery,
  leaderboard,
  news,
  homestats: homeStats,
  reward
};
