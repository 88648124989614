import { combineReducers } from "redux";

import app from "./app.reducer";
import auth from "./auth.reducer";
import home from "./home.reducer";
import userData from "./userData.reducer";
import collections from "./collections.reducer";
import gallery from "./gallery.reducer";
import puzzleGame from "./puzzleGame.reducer";
import leaderboard from "./leaderboard.reducer";
import card from "./card.reducer";
import reward from "./reward.reducer";

const reducers = combineReducers({
  app,
  auth,
  home,
  user: userData,
  collections,
  gallery,
  puzzleGame,
  leaderboard,
  card,
  reward
});

export { reducers };
