import {
  GET_CARD_INFO_REQUEST,
  GET_CARD_INFO_SUCCESS,
  GET_CARD_INFO_FAIL,
} from "../../constants";

const initialState = {
  cardInfo: [],
  loading: false,
};

export default function cardInfo(state = initialState, action) {
  switch (action.type) {
    case GET_CARD_INFO_REQUEST:
      return {
        ...state,
        cardInfo: [],
        loading: true,
      };
    case GET_CARD_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        cardInfo: [...state.cardInfo, action.payload],
      };

    case GET_CARD_INFO_FAIL:
      return {
        ...state,
        loading: false,
        cardInfo: [],
      };

    default:
      return state;
  }
}
